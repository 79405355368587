export const contractLocations = [
  {
    name: 'Atria Residence',
    startLat: 44.8064,
    startLong: 20.4994,
    endLat: 44.8058,
    endLong: 20.4992,
  },
  {
    name: 'Central Palace',
    startLat: 44.8077,
    startLong: 20.4824,
    endLat: 44.8073,
    endLong: 20.4819,
  },
  {
    name: 'Živojina Žujovića 4',
    startLat: 44.8007,
    startLong: 20.4923,
    endLat: 44.8006,
    endLong: 20.4922,
  },
  { name: 'Banjska 21', startLat: 44.8034, startLong: 20.4864, endLat: 44.8033, endLong: 20.4862 },
  {
    name: 'Vojvode Bogdana 39',
    startLat: 44.8028,
    startLong: 20.4856,
    endLat: 44.8027,
    endLong: 20.4853,
  },
  {
    name: 'Vojvode Bogdana 39',
    startLat: 44.8027,
    startLong: 20.4857,
    endLat: 44.8027,
    endLong: 20.4857,
  },
  {
    name: 'Milete Jakšića 2-4',
    startLat: 44.8055,
    startLong: 20.4963,
    endLat: 44.8054,
    endLong: 20.4963,
  },
  {
    name: 'Podujevska 17-19',
    startLat: 44.791,
    startLong: 20.5096,
    endLat: 44.791,
    endLong: 20.5096,
  },
  {
    name: 'Vojislava Ilića 33',
    startLat: 44.7944,
    startLong: 20.4933,
    endLat: 44.7942,
    endLong: 20.493,
  },
  {
    name: 'Ruđera Boškovića 20',
    startLat: 44.7891,
    startLong: 20.5143,
    endLat: 44.7891,
    endLong: 20.5143,
  },
  {
    name: 'Ruđera Boškovića 20',
    startLat: 44.7891,
    startLong: 20.5147,
    endLat: 44.7891,
    endLong: 20.5147,
  },
  {
    name: 'Ruđera Boškovića 20',
    startLat: 44.7892,
    startLong: 20.5144,
    endLat: 44.7892,
    endLong: 20.5144,
  },
  {
    name: 'Milana Rakića 59',
    startLat: 44.7969,
    startLong: 20.5044,
    endLat: 44.7969,
    endLong: 20.5044,
  },
  { name: 'East Side', startLat: 44.7851, startLong: 20.5153, endLat: 44.7848, endLong: 20.5141 },
  { name: 'East Side', startLat: 44.7852, startLong: 20.5158, endLat: 44.7852, endLong: 20.5158 },
  { name: 'East Side', startLat: 44.7852, startLong: 20.5156, endLat: 44.7852, endLong: 20.5156 },
  {
    name: 'Svetog Klimenta 27',
    startLat: 44.7888,
    startLong: 20.5068,
    endLat: 44.7888,
    endLong: 20.5068,
  },
  { name: 'Svetog Klimenta 27', startLat: 44.7888, startLong: 20.507, endLat: 44.7888, endLong: 20.507 },
  { name: 'Subotička 17', startLat: 44.793, startLong: 20.4999, endLat: 44.793, endLong: 20.4999 },
  {
    name: 'Luke Vukalovića 6',
    startLat: 44.8029,
    startLong: 20.4832,
    endLat: 44.8029,
    endLong: 20.4832,
  },
  { name: 'CEP Palace', startLat: 44.8031, startLong: 20.4832, endLat: 44.8031, endLong: 20.4832 },
  { name: 'CEP Palace', startLat: 44.8032, startLong: 20.4832, endLat: 44.8032, endLong: 20.4832 },
  { name: 'MO 34', startLat: 44.8037, startLong: 20.4883, endLat: 44.8037, endLong: 20.4883 },
  { name: 'Prespanska 17', startLat: 44.7956, startLong: 20.494, endLat: 44.7956, endLong: 20.494 },
  { name: 'Šabačka 14', startLat: 44.8005, startLong: 20.4931, endLat: 44.8005, endLong: 20.4931 },
  {
    name: 'Varovnička 1-3',
    startLat: 44.8017,
    startLong: 20.4983,
    endLat: 44.8017,
    endLong: 20.4983,
  },
  { name: 'Krfska 17', startLat: 44.804, startLong: 20.492, endLat: 44.804, endLong: 20.492 },
  {
    name: 'Koste Abraševića 23',
    startLat: 44.7934,
    startLong: 20.501,
    endLat: 44.7934,
    endLong: 20.501,
  },
  {
    name: 'Forte Massiv',
    startLat: 44.8033,
    startLong: 20.4866,
    endLat: 44.8033,
    endLong: 20.4866,
  },
  {
    name: 'Milana Rakića 51-53',
    startLat: 44.7971,
    startLong: 20.5037,
    endLat: 44.797,
    endLong: 20.5035,
  },
  {
    name: 'Milana Rakića 51-53',
    startLat: 44.797,
    startLong: 20.5039,
    endLat: 44.797,
    endLong: 20.5039,
  },
  { name: 'Alpha City', startLat: 44.7873, startLong: 20.515, endLat: 44.7873, endLong: 20.515 },
  {
    name: 'Živojina Žujovića 17',
    startLat: 44.8021,
    startLong: 20.4925,
    endLat: 44.8021,
    endLong: 20.4925,
  },
  {
    name: 'Živka Davidovića 52',
    startLat: 44.7903,
    startLong: 20.512,
    endLat: 44.7903,
    endLong: 20.512,
  },
  { name: 'Kačićeva 12', startLat: 44.7867, startLong: 20.5123, endLat: 44.7867, endLong: 20.5123 },
  {
    name: 'Jovana Đaje 12',
    startLat: 44.7945,
    startLong: 20.5141,
    endLat: 44.7945,
    endLong: 20.5141,
  },
  {
    name: 'Jovana Đaje 12',
    startLat: 44.7946,
    startLong: 20.5139,
    endLat: 44.7946,
    endLong: 20.5139,
  },
  { name: 'Banjska 13', startLat: 44.8039, startLong: 20.4857, endLat: 44.8039, endLong: 20.4857 },
  { name: 'Ulcinjska 2', startLat: 44.7932, startLong: 20.4966, endLat: 44.7932, endLong: 20.4966 },
  {
    name: 'Dimitrija Tucovića 48',
    startLat: 44.8039,
    startLong: 20.4867,
    endLat: 44.8039,
    endLong: 20.4867,
  },
  {
    name: 'Dimitrija Tucovića 123',
    startLat: 44.8014,
    startLong: 20.4939,
    endLat: 44.8014,
    endLong: 20.4939,
  },
  { name: 'Zeleno Brdo', startLat: 44.7866, startLong: 20.5185, endLat: 44.7866, endLong: 20.5185 },
  {
    name: 'Mariborska 6',
    startLat: 44.8031,
    startLong: 20.4901,
    endLat: 44.8031,
    endLong: 20.4901,
  },
  {
    name: 'Zvezdarski Park',
    startLat: 44.7917,
    startLong: 20.5201,
    endLat: 44.7914,
    endLong: 20.5197,
  },
  {
    name: 'Vojvode Blažete 44',
    startLat: 44.7918,
    startLong: 20.5141,
    endLat: 44.7918,
    endLong: 20.5141,
  },
  {
    name: 'Uroša Trojanovića 4',
    startLat: 44.8047,
    startLong: 20.4966,
    endLat: 44.8047,
    endLong: 20.4966,
  },
  {
    name: 'Ljubice Luković 10',
    startLat: 44.8087,
    startLong: 20.4973,
    endLat: 44.8087,
    endLong: 20.4973,
  },
  { name: 'Đurićeva 22', startLat: 44.8045, startLong: 20.4911, endLat: 44.8045, endLong: 20.4911 },
  {
    name: 'Pante Srećkovića',
    startLat: 44.8076,
    startLong: 20.496,
    endLat: 44.8076,
    endLong: 20.496,
  },
  {
    name: 'Čiča Ilijina 4',
    startLat: 44.8021,
    startLong: 20.4882,
    endLat: 44.8021,
    endLong: 20.4882,
  },
  {
    name: 'Čiča Ilijina 6',
    startLat: 44.8022,
    startLong: 20.4885,
    endLat: 44.8022,
    endLong: 20.4885,
  },
  {
    name: 'Kulina Bana 2',
    startLat: 44.7944,
    startLong: 20.4944,
    endLat: 44.7944,
    endLong: 20.4944,
  },
  {
    name: 'Živojina Žujovića 19',
    startLat: 44.8022,
    startLong: 20.4926,
    endLat: 44.8022,
    endLong: 20.4926,
  },
  {
    name: 'Dr Velizara Kosanovića 33',
    startLat: 44.7895,
    startLong: 20.5063,
    endLat: 44.7895,
    endLong: 20.5063,
  },
  {
    name: 'Mehmeda Sokolovića 3',
    startLat: 44.8041,
    startLong: 20.4928,
    endLat: 44.8039,
    endLong: 20.4925,
  },
  {
    name: 'Koste Trifkovića 10',
    startLat: 44.789,
    startLong: 20.5193,
    endLat: 44.789,
    endLong: 20.5193,
  },
  {
    name: 'Sunrise Residence',
    startLat: 44.7825,
    startLong: 20.5229,
    endLat: 44.7823,
    endLong: 20.5228,
  },
  {
    name: 'Sunrise Residence',
    startLat: 44.7825,
    startLong: 20.5231,
    endLat: 44.7825,
    endLong: 20.5231,
  },
  {
    name: 'Sunrise Residence',
    startLat: 44.7821,
    startLong: 20.523,
    endLat: 44.7821,
    endLong: 20.523,
  },
  {
    name: 'Sunrise Residence',
    startLat: 44.7823,
    startLong: 20.523,
    endLat: 44.7823,
    endLong: 20.523,
  },
  { name: 'Bulevard', startLat: 44.7886, startLong: 20.516, endLat: 44.7886, endLong: 20.516 },
  {
    name: 'Kajmakčalanska 27',
    startLat: 44.7988,
    startLong: 20.4878,
    endLat: 44.7988,
    endLong: 20.4878,
  },
  { name: 'Šabačka 11', startLat: 44.8006, startLong: 20.4928, endLat: 44.8006, endLong: 20.4927 },
  { name: 'Rtanjska 2', startLat: 44.806, startLong: 20.4884, endLat: 44.806, endLong: 20.4884 },
  {
    name: 'Kajmakčalanska 38',
    startLat: 44.7981,
    startLong: 20.4896,
    endLat: 44.7981,
    endLong: 20.4896,
  },
  {
    name: 'Živojina Žujovića 15',
    startLat: 44.802,
    startLong: 20.4926,
    endLat: 44.802,
    endLong: 20.4925,
  },
  { name: 'Preševska 21', startLat: 44.8002, startLong: 20.493, endLat: 44.8002, endLong: 20.4928 },
  {
    name: 'Preševska 19',
    startLat: 44.8003,
    startLong: 20.4927,
    endLat: 44.8003,
    endLong: 20.4927,
  },
  {
    name: 'Ljube Davidovića 4',
    startLat: 44.791,
    startLong: 20.5053,
    endLat: 44.791,
    endLong: 20.5053,
  },
  {
    name: 'Prespanska 6',
    startLat: 44.7948,
    startLong: 20.4936,
    endLat: 44.7946,
    endLong: 20.4934,
  },
  {
    name: 'Nerodimska 6',
    startLat: 44.8088,
    startLong: 20.4981,
    endLat: 44.8088,
    endLong: 20.4981,
  },
  {
    name: 'Stjepana Ljubiše 27',
    startLat: 44.807,
    startLong: 20.4838,
    endLat: 44.807,
    endLong: 20.4838,
  },
  {
    name: 'Bregalnička 22',
    startLat: 44.796,
    startLong: 20.4905,
    endLat: 44.7959,
    endLong: 20.4904,
  },
  {
    name: 'Generala Zaha 14',
    startLat: 44.79,
    startLong: 20.5083,
    endLat: 44.79,
    endLong: 20.5081,
  },
  {
    name: 'Voje Veljkovića 15',
    startLat: 44.7877,
    startLong: 20.5179,
    endLat: 44.7877,
    endLong: 20.5179,
  },
  {
    name: 'Voje Veljkovića 15',
    startLat: 44.7877,
    startLong: 20.5177,
    endLat: 44.7877,
    endLong: 20.5177,
  },
  {
    name: 'Dimitrija Tucovića 25',
    startLat: 44.8058,
    startLong: 20.4844,
    endLat: 44.8058,
    endLong: 20.4844,
  },
  {
    name: 'Đevđelijska 17',
    startLat: 44.8006,
    startLong: 20.4892,
    endLat: 44.8006,
    endLong: 20.4892,
  },
  {
    name: 'Mite Ružića 3',
    startLat: 44.7913,
    startLong: 20.5138,
    endLat: 44.7913,
    endLong: 20.5138,
  },
  {
    name: 'Subotička 24',
    startLat: 44.7927,
    startLong: 20.4999,
    endLat: 44.7927,
    endLong: 20.4999,
  },
  {
    name: 'Subotička 24',
    startLat: 44.7925,
    startLong: 20.4999,
    endLat: 44.7925,
    endLong: 20.4999,
  },
  {
    name: 'Ljube Davidovića 10',
    startLat: 44.7909,
    startLong: 20.5052,
    endLat: 44.7909,
    endLong: 20.5052,
  },
  {
    name: 'Branka Krsmanovića 15',
    startLat: 44.7993,
    startLong: 20.4882,
    endLat: 44.7993,
    endLong: 20.4882,
  },
  {
    name: 'Vojislava Ilića 41',
    startLat: 44.7939,
    startLong: 20.4943,
    endLat: 44.7939,
    endLong: 20.4943,
  },
  {
    name: 'Svetog Klimenta 42',
    startLat: 44.789,
    startLong: 20.5045,
    endLat: 44.789,
    endLong: 20.5045,
  },
  { name: 'Čelopečka 17', startLat: 44.806, startLong: 20.4807, endLat: 44.806, endLong: 20.4807 },
  {
    name: 'Čelopečka 11',
    startLat: 44.8059,
    startLong: 20.4803,
    endLat: 44.8059,
    endLong: 20.4803,
  },
  {
    name: 'Vojvode Bogdana 12',
    startLat: 44.8047,
    startLong: 20.4818,
    endLat: 44.8047,
    endLong: 20.4818,
  },
  {
    name: 'BW Kula Beograd',
    startLat: 44.8055,
    startLong: 20.447,
    endLat: 44.8055,
    endLong: 20.447,
  },
  { name: 'Resavska 25', startLat: 44.8073, startLong: 20.4658, endLat: 44.8073, endLong: 20.4658 },
  {
    name: 'Residence Puškinova',
    startLat: 44.7866,
    startLong: 20.4343,
    endLat: 44.7865,
    endLong: 20.4341,
  },
  {
    name: "BW King's Park i Queen's Park",
    startLat: 44.8067,
    startLong: 20.4527,
    endLat: 44.8067,
    endLong: 20.4527,
  },
  { name: 'Novi Dorćol', startLat: 44.8212, startLong: 20.4746, endLat: 44.82, endLong: 20.4726 },
  {
    name: 'Lamartinova 18',
    startLat: 44.7935,
    startLong: 20.4692,
    endLat: 44.7935,
    endLong: 20.4692,
  },
  {
    name: 'BW Arcadia i Aurora',
    startLat: 44.8055,
    startLong: 20.4501,
    endLat: 44.8055,
    endLong: 20.4501,
  },
  {
    name: 'BW Verde i Aqua',
    startLat: 44.805,
    startLong: 20.4498,
    endLat: 44.805,
    endLong: 20.4498,
  },
  {
    name: 'BW Verde i Aqua',
    startLat: 44.8049,
    startLong: 20.4493,
    endLat: 44.8049,
    endLong: 20.4493,
  },
  {
    name: 'BW Verde i Aqua',
    startLat: 44.8051,
    startLong: 20.4504,
    endLat: 44.8051,
    endLong: 20.4504,
  },
  { name: 'K-District', startLat: 44.8285, startLong: 20.4546, endLat: 44.827, endLong: 20.4524 },
  { name: 'K-District', startLat: 44.828, startLong: 20.4548, endLat: 44.828, endLong: 20.4548 },
  { name: 'K-District', startLat: 44.8284, startLong: 20.4548, endLat: 44.8284, endLong: 20.4548 },
  { name: 'K-District', startLat: 44.828, startLong: 20.455, endLat: 44.828, endLong: 20.455 },
  {
    name: 'Dubrovačka 8',
    startLat: 44.8246,
    startLong: 20.4614,
    endLat: 44.8244,
    endLong: 20.4611,
  },
  {
    name: 'BW Sole, Scala i Libera',
    startLat: 44.8021,
    startLong: 20.4486,
    endLat: 44.8021,
    endLong: 20.4486,
  },
  {
    name: 'BW Sole, Scala i Libera',
    startLat: 44.8022,
    startLong: 20.4495,
    endLat: 44.8022,
    endLong: 20.4495,
  },
  { name: 'BW Sole, Scala i Libera', startLat: 44.8026, startLong: 20.4481, endLat: 44.8026, endLong: 20.4481 },
  {
    name: 'Landmark Residence',
    startLat: 44.8027,
    startLong: 20.4811,
    endLat: 44.8027,
    endLong: 20.481,
  },
  {
    name: 'Landmark Residence',
    startLat: 44.8026,
    startLong: 20.4814,
    endLat: 44.8026,
    endLong: 20.4814,
  },
  {
    name: 'Carigradska 18',
    startLat: 44.8181,
    startLong: 20.4732,
    endLat: 44.8181,
    endLong: 20.4732,
  },
  {
    name: 'Park Vila Residence',
    startLat: 44.7609,
    startLong: 20.4825,
    endLat: 44.7603,
    endLong: 20.4816,
  },
  { name: 'GDS Petica', startLat: 44.8027, startLong: 20.3821, endLat: 44.8023, endLong: 20.381 },
  { name: 'GDS Petica', startLat: 44.8028, startLong: 20.3821, endLat: 44.8028, endLong: 20.3821 },
  { name: 'Blok 32', startLat: 44.8198, startLong: 20.4126, endLat: 44.8193, endLong: 20.4118 },
  {
    name: 'Kennedy Residence',
    startLat: 44.8332,
    startLong: 20.4118,
    endLat: 44.8328,
    endLong: 20.4117,
  },
  { name: 'Gramšijeva', startLat: 44.8328, startLong: 20.4156, endLat: 44.8325, endLong: 20.4152 },
  { name: 'Park 11', startLat: 44.8256, startLong: 20.4173, endLat: 44.8248, endLong: 20.4168 },
  { name: 'Savada 1', startLat: 44.8071, startLong: 20.4006, endLat: 44.8068, endLong: 20.3996 },
  { name: 'ZepTerra', startLat: 44.8139, startLong: 20.4, endLat: 44.8134, endLong: 20.3993 },
  { name: 'Wellport', startLat: 44.8132, startLong: 20.3992, endLat: 44.8128, endLong: 20.3981 },
  { name: 'West 65', startLat: 44.8134, startLong: 20.4018, endLat: 44.8124, endLong: 20.3999 },
  { name: 'The One', startLat: 44.8096, startLong: 20.3994, endLat: 44.8088, endLong: 20.398 },
  { name: 'The One', startLat: 44.8098, startLong: 20.3986, endLat: 44.8098, endLong: 20.3986 },
  { name: 'The One', startLat: 44.8097, startLong: 20.3994, endLat: 44.8097, endLong: 20.3994 },
  {
    name: 'Elixir Garden',
    startLat: 44.8155,
    startLong: 20.3959,
    endLat: 44.8153,
    endLong: 20.3953,
  },
  {
    name: 'EX ING Home 65',
    startLat: 44.8153,
    startLong: 20.3965,
    endLat: 44.815,
    endLong: 20.3963,
  },
  { name: 'Savada 3', startLat: 44.8125, startLong: 20.392, endLat: 44.812, endLong: 20.3913 },
  { name: 'New Minel', startLat: 44.8146, startLong: 20.3908, endLat: 44.8142, endLong: 20.3891 },
  { name: 'Sakura park', startLat: 44.8033, startLong: 20.4101, endLat: 44.8032, endLong: 20.4096 },
  { name: 'Lastavice', startLat: 44.8034, startLong: 20.4083, endLat: 44.8034, endLong: 20.4079 },
  { name: 'Lastavice', startLat: 44.8034, startLong: 20.4075, endLat: 44.8034, endLong: 20.4075 },
  { name: 'Square 43', startLat: 44.8059, startLong: 20.4236, endLat: 44.8058, endLong: 20.4235 },
  { name: 'NOVA45', startLat: 44.7993, startLong: 20.3765, endLat: 44.7991, endLong: 20.3761 },
  { name: 'Blok 63', startLat: 44.8098, startLong: 20.382, endLat: 44.8096, endLong: 20.3815 },
  { name: 'V Park', startLat: 44.8092, startLong: 20.3789, endLat: 44.8092, endLong: 20.3787 },
  { name: 'V Park', startLat: 44.8089, startLong: 20.3783, endLat: 44.8089, endLong: 20.3783 },
  { name: 'V Park', startLat: 44.8091, startLong: 20.3787, endLat: 44.8091, endLong: 20.3787 },
  { name: 'V12', startLat: 44.8102, startLong: 20.3815, endLat: 44.8102, endLong: 20.3815 },
  { name: 'Kosa Kvart', startLat: 44.8068, startLong: 20.3663, endLat: 44.8068, endLong: 20.3663 },
  { name: 'Kosa Kvart', startLat: 44.8068, startLong: 20.3651, endLat: 44.8068, endLong: 20.3651 },
  { name: 'Kosa Kvart', startLat: 44.8062, startLong: 20.3654, endLat: 44.8062, endLong: 20.3654 },
  { name: 'Kosa Kvart', startLat: 44.8067, startLong: 20.3664, endLat: 44.8067, endLong: 20.3664 },
  { name: 'Kosa Kvart', startLat: 44.8063, startLong: 20.3652, endLat: 44.8063, endLong: 20.3652 },
  { name: 'Kosa Kvart', startLat: 44.8067, startLong: 20.3649, endLat: 44.8067, endLong: 20.3649 },
  { name: 'Kosa Kvart', startLat: 44.8071, startLong: 20.3657, endLat: 44.8071, endLong: 20.3657 },
  { name: 'Kosa Kvart', startLat: 44.8071, startLong: 20.3661, endLat: 44.8071, endLong: 20.3661 },
  { name: 'Kosa Kvart', startLat: 44.8066, startLong: 20.3662, endLat: 44.8066, endLong: 20.3662 },
  { name: 'Kosa Kvart', startLat: 44.8072, startLong: 20.3659, endLat: 44.8072, endLong: 20.3659 },
  { name: 'Kosa Kvart', startLat: 44.8063, startLong: 20.3656, endLat: 44.8063, endLong: 20.3656 },
  {
    name: 'Plato Bežanija',
    startLat: 44.8142,
    startLong: 20.3754,
    endLat: 44.8139,
    endLong: 20.3753,
  },
  { name: 'Sky Home', startLat: 44.8079, startLong: 20.3715, endLat: 44.8076, endLong: 20.371 },
  { name: 'Sky Home', startLat: 44.8082, startLong: 20.3709, endLat: 44.8082, endLong: 20.3709 },
  { name: 'BW Quartet', startLat: 44.804, startLong: 20.4518, endLat: 44.804, endLong: 20.4518 },
  { name: 'BW Quartet', startLat: 44.8036, startLong: 20.4513, endLat: 44.8036, endLong: 20.4513 },
  { name: 'BW Quartet', startLat: 44.8048, startLong: 20.4522, endLat: 44.8048, endLong: 20.4522 },
  { name: 'BW Quartet', startLat: 44.8061, startLong: 20.4535, endLat: 44.8061, endLong: 20.4535 },
  { name: 'BW Quartet', startLat: 44.8052, startLong: 20.4527, endLat: 44.8052, endLong: 20.4527 },
  { name: 'BW Elements', startLat: 44.8019, startLong: 20.4502, endLat: 44.8019, endLong: 20.4502 },
  { name: 'BW Elements', startLat: 44.8023, startLong: 20.4505, endLat: 44.8023, endLong: 20.4505 },
  { name: 'BW Elements', startLat: 44.8014, startLong: 20.4492, endLat: 44.8014, endLong: 20.4492 },
  { name: 'BW Elements', startLat: 44.8025, startLong: 20.4504, endLat: 44.8025, endLong: 20.4504 },
  { name: 'BW Elements', startLat: 44.8011, startLong: 20.4492, endLat: 44.8011, endLong: 20.4492 },
  { name: 'GV13+', startLat: 44.791, startLong: 20.4732, endLat: 44.791, endLong: 20.4732 },
  {
    name: 'Skender Begova 45',
    startLat: 44.8225,
    startLong: 20.4639,
    endLat: 44.8224,
    endLong: 20.4635,
  },
  {
    name: 'Kneza Danila 4a',
    startLat: 44.8123,
    startLong: 20.4756,
    endLat: 44.8123,
    endLong: 20.4755,
  },
  {
    name: 'Vojvoda Residence',
    startLat: 44.7637,
    startLong: 20.4843,
    endLat: 44.7637,
    endLong: 20.4843,
  },
  {
    name: 'Vojvoda Residence',
    startLat: 44.7637,
    startLong: 20.4841,
    endLat: 44.7637,
    endLong: 20.4841,
  },
  {
    name: 'Palata Cvijićeva',
    startLat: 44.819,
    startLong: 20.4772,
    endLat: 44.8189,
    endLong: 20.4771,
  },
  {
    name: 'Green Residence',
    startLat: 44.8162,
    startLong: 20.487,
    endLat: 44.8162,
    endLong: 20.487,
  },
  {
    name: 'Green Residence',
    startLat: 44.8166,
    startLong: 20.4866,
    endLat: 44.8166,
    endLong: 20.4866,
  },
  {
    name: 'Green Residence',
    startLat: 44.8166,
    startLong: 20.4869,
    endLat: 44.8166,
    endLong: 20.4869,
  },
  {
    name: 'Green Residence',
    startLat: 44.8163,
    startLong: 20.4871,
    endLat: 44.8163,
    endLong: 20.4871,
  },
  {
    name: 'Green Residence',
    startLat: 44.8164,
    startLong: 20.4875,
    endLat: 44.8164,
    endLong: 20.4875,
  },
  { name: 'Green Residence', startLat: 44.8162, startLong: 20.4877, endLat: 44.8162, endLong: 20.4877 },
  {
    name: 'Voždove kapije',
    startLat: 44.766,
    startLong: 20.4821,
    endLat: 44.7648,
    endLong: 20.4807,
  },
  {
    name: 'Voždove kapije',
    startLat: 44.7644,
    startLong: 20.481,
    endLat: 44.7644,
    endLong: 20.481,
  },
  { name: 'Đerdapska 9', startLat: 44.7942, startLong: 20.4863, endLat: 44.7942, endLong: 20.4863 },
  { name: 'Beranska 1', startLat: 44.7592, startLong: 20.483, endLat: 44.7589, endLong: 20.4824 },
  {
    name: 'Davida Pajića 21',
    startLat: 44.7915,
    startLong: 20.4853,
    endLat: 44.7915,
    endLong: 20.4853,
  },
  {
    name: 'Bulevar Peka Dapčevića 24v-24g',
    startLat: 44.7677,
    startLong: 20.4849,
    endLat: 44.7675,
    endLong: 20.4848,
  },
  {
    name: 'Zemunske kapije',
    startLat: 44.8558,
    startLong: 20.3856,
    endLat: 44.8553,
    endLong: 20.3847,
  },
  {
    name: 'Zemunske kapije',
    startLat: 44.8551,
    startLong: 20.3854,
    endLat: 44.855,
    endLong: 20.3852,
  },
  {
    name: 'Zemunske kapije',
    startLat: 44.8556,
    startLong: 20.3851,
    endLat: 44.8543,
    endLong: 20.3822,
  },
  {
    name: 'Zemunske kapije',
    startLat: 44.8559,
    startLong: 20.3839,
    endLat: 44.8559,
    endLong: 20.3839,
  },
  {
    name: 'BW Residences',
    startLat: 44.8101,
    startLong: 20.4497,
    endLat: 44.8083,
    endLong: 20.4492,
  },
  { name: 'BW Terraces', startLat: 44.8078, startLong: 20.4497, endLat: 44.8076, endLong: 20.4488 },
  {
    name: 'Kuršumlijska 3',
    startLat: 44.7947,
    startLong: 20.4458,
    endLat: 44.7947,
    endLong: 20.4458,
  },
  {
    name: 'Palace One Dedinje',
    startLat: 44.7735,
    startLong: 20.4624,
    endLat: 44.7735,
    endLong: 20.4624,
  },
  {
    name: 'Nebojšina 49a-49b',
    startLat: 44.7932,
    startLong: 20.4684,
    endLat: 44.7931,
    endLong: 20.4682,
  },
  { name: 'Skyline', startLat: 44.8, startLong: 20.4517, endLat: 44.8, endLong: 20.4517 },
  {
    name: 'Dorćol Prime',
    startLat: 44.8234,
    startLong: 20.4635,
    endLat: 44.8234,
    endLong: 20.4635,
  },
  {
    name: 'Cara Uroša 31',
    startLat: 44.8233,
    startLong: 20.4575,
    endLat: 44.8233,
    endLong: 20.4575,
  },
  {
    name: 'Šumatovačka 22',
    startLat: 44.7954,
    startLong: 20.4709,
    endLat: 44.7954,
    endLong: 20.4709,
  },
  {
    name: 'Majora Ilića 9',
    startLat: 44.812,
    startLong: 20.4723,
    endLat: 44.812,
    endLong: 20.4723,
  },
  {
    name: 'Knez Miletina 26',
    startLat: 44.8187,
    startLong: 20.4713,
    endLat: 44.8187,
    endLong: 20.4713,
  },
  { name: 'CU37', startLat: 44.8245, startLong: 20.4588, endLat: 44.8244, endLong: 20.4585 },
  {
    name: 'Stojana Protića 31',
    startLat: 44.7968,
    startLong: 20.4724,
    endLat: 44.7966,
    endLong: 20.4721,
  },
  {
    name: 'Nikolaja Hartviga 8',
    startLat: 44.7959,
    startLong: 20.4739,
    endLat: 44.7959,
    endLong: 20.4739,
  },
  {
    name: 'Nikolaja Hartviga 12',
    startLat: 44.7959,
    startLong: 20.4756,
    endLat: 44.7959,
    endLong: 20.4756,
  },
  { name: 'Nikolaja Hartviga 12', startLat: 44.7959, startLong: 20.4758, endLat: 44.7959, endLong: 20.4758 },
  {
    name: 'BW Arcadia i Aurora',
    startLat: 44.8058,
    startLong: 20.4498,
    endLat: 44.8058,
    endLong: 20.4498,
  },
  {
    name: 'Dimitrija Tucovića 124-126',
    startLat: 44.8006,
    startLong: 20.4955,
    endLat: 44.8006,
    endLong: 20.4955,
  },
  {
    name: 'Černiševskog 5',
    startLat: 44.7966,
    startLong: 20.4757,
    endLat: 44.7966,
    endLong: 20.4757,
  },
  {
    name: 'Miće Orlovića',
    startLat: 44.7645,
    startLong: 20.4594,
    endLat: 44.7645,
    endLong: 20.4594,
  },
  {
    name: 'Kneza Miloša Residence',
    startLat: 44.8037,
    startLong: 20.4573,
    endLat: 44.8037,
    endLong: 20.4573,
  },
  {
    name: 'Kneza Miloša Residence',
    startLat: 44.8044,
    startLong: 20.4574,
    endLat: 44.8038,
    endLong: 20.4562,
  },
  {
    name: 'Green Hill Dedinje',
    startLat: 44.7622,
    startLong: 20.4602,
    endLat: 44.7611,
    endLong: 20.4599,
  },
  {
    name: 'Dorćol Centar',
    startLat: 44.8231,
    startLong: 20.4611,
    endLat: 44.8231,
    endLong: 20.4611,
  },
  {
    name: 'Dorćol Centar',
    startLat: 44.8231,
    startLong: 20.4615,
    endLat: 44.8231,
    endLong: 20.4615,
  },
  { name: 'Dravska 6', startLat: 44.7972, startLong: 20.4893, endLat: 44.7972, endLong: 20.4893 },
  { name: 'Zahumska 1', startLat: 44.8053, startLong: 20.4802, endLat: 44.8053, endLong: 20.4802 },
  {
    name: 'Vojvode Brane 51',
    startLat: 44.8036,
    startLong: 20.4856,
    endLat: 44.8036,
    endLong: 20.4856,
  },
  { name: 'Krfska 21', startLat: 44.8042, startLong: 20.4923, endLat: 44.8042, endLong: 20.4923 },
  {
    name: 'Ljube Davidovića 46',
    startLat: 44.7898,
    startLong: 20.503,
    endLat: 44.7898,
    endLong: 20.503,
  },
  {
    name: 'Gvozdićeva 4',
    startLat: 44.7976,
    startLong: 20.4931,
    endLat: 44.7976,
    endLong: 20.4931,
  },
  {
    name: 'Kopernikus Panorama',
    startLat: 44.7923,
    startLong: 20.4999,
    endLat: 44.792,
    endLong: 20.4996,
  },
  {
    name: 'Kopernikus Panorama',
    startLat: 44.7921,
    startLong: 20.5002,
    endLat: 44.7921,
    endLong: 20.5002,
  },
  {
    name: 'Kopernikus Panorama',
    startLat: 44.792,
    startLong: 20.5002,
    endLat: 44.792,
    endLong: 20.5002,
  },
  {
    name: 'Kopernikus Panorama',
    startLat: 44.792,
    startLong: 20.5004,
    endLat: 44.792,
    endLong: 20.5004,
  },
  {
    name: 'Kopernikus Panorama',
    startLat: 44.7921,
    startLong: 20.5003,
    endLat: 44.7921,
    endLong: 20.5003,
  },
  {
    name: 'Vojislava Ilića 29',
    startLat: 44.7945,
    startLong: 20.4927,
    endLat: 44.7945,
    endLong: 20.4927,
  },
  {
    name: 'Preševska 36',
    startLat: 44.7996,
    startLong: 20.4944,
    endLat: 44.7996,
    endLong: 20.4944,
  },
  {
    name: 'Đevđelijska 69',
    startLat: 44.7992,
    startLong: 20.4942,
    endLat: 44.7992,
    endLong: 20.4942,
  },
  {
    name: 'Kajmakčalanska 37',
    startLat: 44.7987,
    startLong: 20.4887,
    endLat: 44.7987,
    endLong: 20.4887,
  },
  {
    name: 'Vojvode Blažete 38',
    startLat: 44.792,
    startLong: 20.5138,
    endLat: 44.792,
    endLong: 20.5138,
  },
  {
    name: 'Vojvode Blažete 40',
    startLat: 44.7919,
    startLong: 20.5139,
    endLat: 44.7919,
    endLong: 20.5139,
  },
  {
    name: 'Slobodanke-Danke Savić 7',
    startLat: 44.793,
    startLong: 20.5026,
    endLat: 44.793,
    endLong: 20.5026,
  },
  { name: 'Kovačeva 15', startLat: 44.7884, startLong: 20.5064, endLat: 44.7884, endLong: 20.5064 },
  { name: 'Kovačeva 17', startLat: 44.7884, startLong: 20.5063, endLat: 44.7884, endLong: 20.5063 },
  {
    name: 'Čardaklijina 2',
    startLat: 44.7916,
    startLong: 20.5089,
    endLat: 44.7916,
    endLong: 20.5089,
  },
  {
    name: 'Dragutina Milutinovića 3',
    startLat: 44.7879,
    startLong: 20.5094,
    endLat: 44.7879,
    endLong: 20.5094,
  },
  {
    name: 'Hadži Mustafina 22',
    startLat: 44.8007,
    startLong: 20.4936,
    endLat: 44.8007,
    endLong: 20.4936,
  },
  { name: 'Čegarska 3', startLat: 44.802, startLong: 20.4957, endLat: 44.802, endLong: 20.4957 },
  { name: 'Dravska 14', startLat: 44.7977, startLong: 20.4895, endLat: 44.7977, endLong: 20.4895 },
  {
    name: 'Vojvode Savatija 23',
    startLat: 44.8021,
    startLong: 20.4894,
    endLat: 44.8021,
    endLong: 20.4894,
  },
  {
    name: 'Svetog Nikole 26',
    startLat: 44.8071,
    startLong: 20.4862,
    endLat: 44.8071,
    endLong: 20.4862,
  },
  {
    name: 'Zvezdarski vidik',
    startLat: 44.7909,
    startLong: 20.5116,
    endLat: 44.7909,
    endLong: 20.5116,
  },
  {
    name: 'Vojislava Ilića 47',
    startLat: 44.7936,
    startLong: 20.495,
    endLat: 44.7936,
    endLong: 20.495,
  },
  {
    name: 'Ljubljanska 3-5',
    startLat: 44.8022,
    startLong: 20.4936,
    endLat: 44.8022,
    endLong: 20.4936,
  },
  {
    name: 'Ulcinjska 29',
    startLat: 44.7919,
    startLong: 20.4986,
    endLat: 44.7919,
    endLong: 20.4986,
  },
  {
    name: 'Ulcinjska 33',
    startLat: 44.7918,
    startLong: 20.4989,
    endLat: 44.7918,
    endLong: 20.4989,
  },
  {
    name: 'Pljevaljska 24',
    startLat: 44.7852,
    startLong: 20.5078,
    endLat: 44.7852,
    endLong: 20.5078,
  },
  {
    name: 'Voje Veljkovića 10',
    startLat: 44.7875,
    startLong: 20.5175,
    endLat: 44.7875,
    endLong: 20.5175,
  },
  {
    name: 'Bojadžinska 17-19',
    startLat: 44.7809,
    startLong: 20.5135,
    endLat: 44.7806,
    endLong: 20.5134,
  },
  {
    name: 'Mihaila Todorovića 22',
    startLat: 44.7805,
    startLong: 20.5121,
    endLat: 44.7805,
    endLong: 20.512,
  },
  { name: 'Bulevar 515', startLat: 44.7771, startLong: 20.532, endLat: 44.7771, endLong: 20.532 },
  {
    name: 'Sunnyville',
    startLat: 44.8136,
    startLong: 20.5375,
    endLat: 44.8111,
    endLong: 20.5351,
  },
  {
    name: 'Sunnyville',
    startLat: 44.8124,
    startLong: 20.5377,
    endLat: 44.8124,
    endLong: 20.5377,
  },
  { name: 'Sunnyville', startLat: 44.8134, startLong: 20.5403, endLat: 44.811, endLong: 20.5379 },
  { name: 'Sunnyville', startLat: 44.8128, startLong: 20.5378, endLat: 44.8128, endLong: 20.5378 },
  {
    name: 'Milorada Dimitrijevića',
    startLat: 44.8053,
    startLong: 20.5267,
    endLat: 44.8032,
    endLong: 20.5261,
  },
  {
    name: 'Strumička 59',
    startLat: 44.7872,
    startLong: 20.4867,
    endLat: 44.7872,
    endLong: 20.4867,
  },
  {
    name: 'Strumička 57',
    startLat: 44.7873,
    startLong: 20.4866,
    endLat: 44.7873,
    endLong: 20.4866,
  },
  {
    name: 'Koste Jovanovića 34',
    startLat: 44.7812,
    startLong: 20.4698,
    endLat: 44.7812,
    endLong: 20.4694,
  },
  {
    name: 'Generala Anrija 16',
    startLat: 44.78,
    startLong: 20.4693,
    endLat: 44.78,
    endLong: 20.4693,
  },
  { name: 'Jove Ilića 51', startLat: 44.7824, startLong: 20.47, endLat: 44.7824, endLong: 20.47 },
  {
    name: 'Jove Ilića 41',
    startLat: 44.7834,
    startLong: 20.4693,
    endLat: 44.7832,
    endLong: 20.4692,
  },
  { name: 'Prilučka 3', startLat: 44.7766, startLong: 20.4729, endLat: 44.7766, endLong: 20.4729 },
  {
    name: 'Belimarkovićeva 22/Dragačevska 4',
    startLat: 44.7901,
    startLong: 20.4924,
    endLat: 44.7899,
    endLong: 20.4923,
  },
  {
    name: 'Nestora Žučnog 17-19',
    startLat: 44.7789,
    startLong: 20.4767,
    endLat: 44.7788,
    endLong: 20.4767,
  },
  { name: 'Bilećka 28', startLat: 44.7689, startLong: 20.4778, endLat: 44.7689, endLong: 20.4778 },
  {
    name: 'Kruševačka 20',
    startLat: 44.7893,
    startLong: 20.4843,
    endLat: 44.7893,
    endLong: 20.4843,
  },
  { name: 'Alterra Una', startLat: 44.7843, startLong: 20.4774, endLat: 44.7843, endLong: 20.4774 },
  {
    name: 'Miklošićeva 24',
    startLat: 44.7902,
    startLong: 20.4826,
    endLat: 44.7902,
    endLong: 20.4826,
  },
  {
    name: 'Rada Neimara 33',
    startLat: 44.7883,
    startLong: 20.4928,
    endLat: 44.7881,
    endLong: 20.4926,
  },
  {
    name: 'Gospodara Vučića 164',
    startLat: 44.7913,
    startLong: 20.491,
    endLat: 44.7913,
    endLong: 20.491,
  },
  {
    name: 'Gospodara Vučića 74',
    startLat: 44.7919,
    startLong: 20.4822,
    endLat: 44.7918,
    endLong: 20.4822,
  },
  {
    name: 'Pašmanska 19',
    startLat: 44.7899,
    startLong: 20.4916,
    endLat: 44.7899,
    endLong: 20.4916,
  },
  {
    name: 'Pašmanska 17',
    startLat: 44.7899,
    startLong: 20.4914,
    endLat: 44.7899,
    endLong: 20.4914,
  },
  {
    name: 'Rade Končara 20',
    startLat: 44.7902,
    startLong: 20.4777,
    endLat: 44.7902,
    endLong: 20.4777,
  },
  {
    name: 'Lepenička 10',
    startLat: 44.7835,
    startLong: 20.4674,
    endLat: 44.7835,
    endLong: 20.4674,
  },
  {
    name: 'Vojvode Stepe 93',
    startLat: 44.7805,
    startLong: 20.4729,
    endLat: 44.7805,
    endLong: 20.4729,
  },
  {
    name: 'Vojvode Stepe 91',
    startLat: 44.7805,
    startLong: 20.4727,
    endLat: 44.7805,
    endLong: 20.4727,
  },
  { name: 'Kumodraška 19', startLat: 44.784, startLong: 20.4761, endLat: 44.784, endLong: 20.4761 },
  {
    name: 'Vojvode Prijezde 16',
    startLat: 44.7899,
    startLong: 20.477,
    endLat: 44.7899,
    endLong: 20.477,
  },
  {
    name: 'Vojvode Stepe 250',
    startLat: 44.7696,
    startLong: 20.4784,
    endLat: 44.7696,
    endLong: 20.4784,
  },
  {
    name: 'Maksima Gorkog 60',
    startLat: 44.7909,
    startLong: 20.4795,
    endLat: 44.7909,
    endLong: 20.4795,
  },
  {
    name: 'Kostolačka 53',
    startLat: 44.7717,
    startLong: 20.4793,
    endLat: 44.7717,
    endLong: 20.4793,
  },
  { name: 'Prešernova 25', startLat: 44.7855, startLong: 20.475, endLat: 44.7855, endLong: 20.475 },
  { name: 'Belička 6', startLat: 44.7905, startLong: 20.4788, endLat: 44.7905, endLong: 20.4787 },
  {
    name: 'Gospodara Vučića 92',
    startLat: 44.7921,
    startLong: 20.4842,
    endLat: 44.792,
    endLong: 20.4842,
  },
  {
    name: 'Vojvode Stepe 47',
    startLat: 44.7839,
    startLong: 20.4702,
    endLat: 44.7839,
    endLong: 20.4702,
  },
  { name: 'Tetovska 22', startLat: 44.7896, startLong: 20.4816, endLat: 44.7896, endLong: 20.4816 },
  {
    name: 'Admirala Vukovića 48',
    startLat: 44.7845,
    startLong: 20.4733,
    endLat: 44.7845,
    endLong: 20.4733,
  },
  {
    name: 'Banjička Oaza',
    startLat: 44.7541,
    startLong: 20.4838,
    endLat: 44.7539,
    endLong: 20.4835,
  },
  { name: 'Merin Hill', startLat: 44.7884, startLong: 20.4794, endLat: 44.7879, endLong: 20.4785 },
  { name: 'Pčinjska 8', startLat: 44.7896, startLong: 20.4758, endLat: 44.7896, endLong: 20.4758 },
  {
    name: 'Ljube Nedića 19',
    startLat: 44.7849,
    startLong: 20.4766,
    endLat: 44.7849,
    endLong: 20.4766,
  },
  {
    name: 'Strumička 54',
    startLat: 44.7873,
    startLong: 20.4859,
    endLat: 44.7873,
    endLong: 20.4859,
  },
  { name: 'Park Šumice', startLat: 44.7887, startLong: 20.4901, endLat: 44.7885, endLong: 20.4892 },
  { name: 'Park Šumice', startLat: 44.7884, startLong: 20.4903, endLat: 44.7884, endLong: 20.4903 },
  {
    name: 'Milice Milojković 17',
    startLat: 44.7633,
    startLong: 20.4809,
    endLat: 44.7633,
    endLong: 20.4809,
  },
  {
    name: 'Vojvode Stepe 232',
    startLat: 44.7708,
    startLong: 20.4779,
    endLat: 44.7708,
    endLong: 20.4779,
  },
  {
    name: 'Belimarkovićeva 11',
    startLat: 44.7906,
    startLong: 20.4906,
    endLat: 44.7906,
    endLong: 20.4906,
  },
  {
    name: 'Jove Ilića 132',
    startLat: 44.7757,
    startLong: 20.4741,
    endLat: 44.7755,
    endLong: 20.474,
  },
  {
    name: 'Braće Kovač 30',
    startLat: 44.7867,
    startLong: 20.4821,
    endLat: 44.7867,
    endLong: 20.4818,
  },
  {
    name: 'Braće Kovač 26',
    startLat: 44.7868,
    startLong: 20.4821,
    endLat: 44.7868,
    endLong: 20.4818,
  },
  {
    name: 'Gospodara Vučića 56',
    startLat: 44.7915,
    startLong: 20.4801,
    endLat: 44.7915,
    endLong: 20.4801,
  },
  {
    name: 'Ljube Šercera 10',
    startLat: 44.7724,
    startLong: 20.4795,
    endLat: 44.7724,
    endLong: 20.4795,
  },
  {
    name: 'Strumička 22',
    startLat: 44.7885,
    startLong: 20.4826,
    endLat: 44.7885,
    endLong: 20.4826,
  },
  {
    name: 'Joakima Rakovca 10',
    startLat: 44.762,
    startLong: 20.4827,
    endLat: 44.762,
    endLong: 20.4827,
  },
  {
    name: 'Ljube Nedića 1',
    startLat: 44.7852,
    startLong: 20.475,
    endLat: 44.7852,
    endLong: 20.4748,
  },
  {
    name: 'Sundečićeva 3',
    startLat: 44.7873,
    startLong: 20.4905,
    endLat: 44.7873,
    endLong: 20.4905,
  },
  { name: 'Ibarska 5', startLat: 44.7884, startLong: 20.4821, endLat: 44.7884, endLong: 20.4821 },
  {
    name: 'Vojvode Toze 3',
    startLat: 44.7901,
    startLong: 20.4913,
    endLat: 44.7901,
    endLong: 20.4913,
  },
  {
    name: 'Vojvode Stepe 164',
    startLat: 44.7752,
    startLong: 20.4751,
    endLat: 44.7752,
    endLong: 20.4751,
  },
  {
    name: 'Vojvode Đurovića 5-7',
    startLat: 44.781,
    startLong: 20.4774,
    endLat: 44.781,
    endLong: 20.4774,
  },
  { name: 'Belička 20', startLat: 44.7907, startLong: 20.4801, endLat: 44.7907, endLong: 20.4801 },
  { name: 'Tetovska 3', startLat: 44.7896, startLong: 20.4791, endLat: 44.7896, endLong: 20.4791 },
  { name: 'Tetovska 3', startLat: 44.7897, startLong: 20.4791, endLat: 44.7897, endLong: 20.4791 },
  {
    name: 'Bože Jankovića 75',
    startLat: 44.7817,
    startLong: 20.4777,
    endLat: 44.7817,
    endLong: 20.4777,
  },
  { name: 'Bačvanska 2', startLat: 44.788, startLong: 20.4939, endLat: 44.788, endLong: 20.4939 },
  {
    name: 'Bulevar oslobođenja 229',
    startLat: 44.7704,
    startLong: 20.4764,
    endLat: 44.7704,
    endLong: 20.4764,
  },
  {
    name: 'Jove Ilića 181',
    startLat: 44.7723,
    startLong: 20.4759,
    endLat: 44.7723,
    endLong: 20.4759,
  },
  {
    name: 'Vojvode Stepe 23',
    startLat: 44.7858,
    startLong: 20.4682,
    endLat: 44.7858,
    endLong: 20.4682,
  },
  {
    name: 'Kumodraška 89',
    startLat: 44.7791,
    startLong: 20.4799,
    endLat: 44.7791,
    endLong: 20.4799,
  },
  {
    name: 'Danijelova 18a',
    startLat: 44.7836,
    startLong: 20.4778,
    endLat: 44.7836,
    endLong: 20.4778,
  },
  {
    name: 'Banjički vrt',
    startLat: 44.7555,
    startLong: 20.4824,
    endLat: 44.7555,
    endLong: 20.4824,
  },
  {
    name: 'Braće Kovač 40',
    startLat: 44.7863,
    startLong: 20.483,
    endLat: 44.7863,
    endLong: 20.483,
  },
  {
    name: 'Vanđela Tome 3',
    startLat: 44.7769,
    startLong: 20.488,
    endLat: 44.7769,
    endLong: 20.488,
  },
  { name: 'Pazinska 11', startLat: 44.7624, startLong: 20.4826, endLat: 44.7624, endLong: 20.4826 },
  {
    name: 'Kumodraška 41',
    startLat: 44.7826,
    startLong: 20.4775,
    endLat: 44.7826,
    endLong: 20.4775,
  },
  { name: 'Tikveška 27', startLat: 44.7894, startLong: 20.4824, endLat: 44.7894, endLong: 20.4824 },
  {
    name: 'Vojvode Stepe 359',
    startLat: 44.7615,
    startLong: 20.4848,
    endLat: 44.7615,
    endLong: 20.4848,
  },
  {
    name: 'Borisavljevićeva 77',
    startLat: 44.783,
    startLong: 20.4762,
    endLat: 44.783,
    endLong: 20.4762,
  },
  {
    name: 'Bože Jankovića 80',
    startLat: 44.7814,
    startLong: 20.4774,
    endLat: 44.7813,
    endLong: 20.4774,
  },
  {
    name: 'Kragujevačkih đaka 6',
    startLat: 44.7543,
    startLong: 20.4763,
    endLat: 44.7543,
    endLong: 20.4763,
  },
  {
    name: 'Vojvode Stepe 431',
    startLat: 44.7551,
    startLong: 20.491,
    endLat: 44.7551,
    endLong: 20.491,
  },
  {
    name: 'Rade Končara 6',
    startLat: 44.7902,
    startLong: 20.4792,
    endLat: 44.7902,
    endLong: 20.4792,
  },
  {
    name: 'Vojvoda Residence',
    startLat: 44.7636,
    startLong: 20.4838,
    endLat: 44.7636,
    endLong: 20.4838,
  },
  { name: 'Urban 88', startLat: 44.7846, startLong: 20.4842, endLat: 44.7846, endLong: 20.4842 },
  {
    name: 'Stepina ljubičica',
    startLat: 44.7621,
    startLong: 20.4875,
    endLat: 44.7621,
    endLong: 20.4875,
  },
  {
    name: 'Danijelova 17',
    startLat: 44.7838,
    startLong: 20.4779,
    endLat: 44.7838,
    endLong: 20.4779,
  },
  {
    name: 'Mickijevičeva 18',
    startLat: 44.7773,
    startLong: 20.4876,
    endLat: 44.7773,
    endLong: 20.4876,
  },
  {
    name: 'Vojvode Stepe 157',
    startLat: 44.7764,
    startLong: 20.4753,
    endLat: 44.7764,
    endLong: 20.4753,
  },
  {
    name: 'Belimarkovićeva 19',
    startLat: 44.7904,
    startLong: 20.4914,
    endLat: 44.7904,
    endLong: 20.4914,
  },
  {
    name: 'Ustanička 94',
    startLat: 44.7844,
    startLong: 20.4848,
    endLat: 44.7844,
    endLong: 20.4847,
  },
  {
    name: 'Strumička 27',
    startLat: 44.7884,
    startLong: 20.4833,
    endLat: 44.7884,
    endLong: 20.4833,
  },
  {
    name: 'Rada Neimara 27-29',
    startLat: 44.7884,
    startLong: 20.4922,
    endLat: 44.7884,
    endLong: 20.4922,
  },
  {
    name: 'Belimarkovićeva 12',
    startLat: 44.7903,
    startLong: 20.491,
    endLat: 44.7903,
    endLong: 20.491,
  },
  {
    name: 'Top Hill Vidik',
    startLat: 44.7437,
    startLong: 20.4288,
    endLat: 44.7432,
    endLong: 20.428,
  },
  {
    name: 'Kneza Višeslava 61-61a',
    startLat: 44.7455,
    startLong: 20.4285,
    endLat: 44.7455,
    endLong: 20.4285,
  },
  {
    name: 'Kneza Višeslava 61-61a',
    startLat: 44.7455,
    startLong: 20.4282,
    endLat: 44.7455,
    endLong: 20.4282,
  },
  {
    name: 'Kneza Višeslava 61-61a',
    startLat: 44.7455,
    startLong: 20.4281,
    endLat: 44.7455,
    endLong: 20.4281,
  },
  {
    name: 'Braničevska 1',
    startLat: 44.7966,
    startLong: 20.4695,
    endLat: 44.7966,
    endLong: 20.4695,
  },
  { name: 'Loznička 13', startLat: 44.8005, startLong: 20.4817, endLat: 44.8005, endLong: 20.4817 },
  {
    name: 'Loznička 7-9-11',
    startLat: 44.8002,
    startLong: 20.4815,
    endLat: 44.8002,
    endLong: 20.4815,
  },
  {
    name: 'Loznička 7-9-11',
    startLat: 44.8004,
    startLong: 20.4818,
    endLat: 44.8004,
    endLong: 20.4818,
  },
  {
    name: 'Loznička 7-9-11',
    startLat: 44.8004,
    startLong: 20.4817,
    endLat: 44.8004,
    endLong: 20.4817,
  },
  {
    name: 'Loznička 7-9-11',
    startLat: 44.8001,
    startLong: 20.4818,
    endLat: 44.8001,
    endLong: 20.4818,
  },
  {
    name: 'Bjelanovićeva 1',
    startLat: 44.7909,
    startLong: 20.4711,
    endLat: 44.7909,
    endLong: 20.4711,
  },
  {
    name: 'Đorđa Vajferta 68',
    startLat: 44.7938,
    startLong: 20.4766,
    endLat: 44.7938,
    endLong: 20.4766,
  },
  {
    name: 'Šumatovačka 135',
    startLat: 44.7941,
    startLong: 20.4843,
    endLat: 44.7941,
    endLong: 20.4843,
  },
  {
    name: 'Stojana Protića 23',
    startLat: 44.7967,
    startLong: 20.4715,
    endLat: 44.7967,
    endLong: 20.4715,
  },
  {
    name: 'Svetozara Gligorića 12',
    startLat: 44.796,
    startLong: 20.4745,
    endLat: 20.4745,
    endLong: 20.4745,
  },
  { name: 'Sazonova 117', startLat: 44.794, startLong: 20.4893, endLat: 44.794, endLong: 20.4893 },
  { name: 'Sazonova 51', startLat: 44.796, startLong: 20.4827, endLat: 44.796, endLong: 20.4827 },
  { name: 'Sazonova 51', startLat: 44.7959, startLong: 20.4828, endLat: 44.7959, endLong: 20.4828 },
  {
    name: 'Kneginje Zorke 28',
    startLat: 44.8008,
    startLong: 20.4695,
    endLat: 44.8008,
    endLong: 20.4695,
  },
  {
    name: 'Šumatovačka 130',
    startLat: 44.7942,
    startLong: 20.4832,
    endLat: 44.7942,
    endLong: 20.4832,
  },
  {
    name: 'Južni bulevar 80',
    startLat: 44.7933,
    startLong: 20.479,
    endLat: 44.7933,
    endLong: 20.479,
  },
  {
    name: 'Skerlićeva 30',
    startLat: 44.7965,
    startLong: 20.4692,
    endLat: 44.7965,
    endLong: 20.4692,
  },
  { name: 'Niška 13', startLat: 44.8024, startLong: 20.4805, endLat: 44.8024, endLong: 20.4805 },
  {
    name: 'Gružanska 15',
    startLat: 44.7966,
    startLong: 20.4879,
    endLat: 44.7966,
    endLong: 20.4879,
  },
  { name: 'Gročanska 9', startLat: 44.7934, startLong: 20.4909, endLat: 44.7934, endLong: 20.4909 },
  {
    name: 'Dubljanska 50',
    startLat: 44.7956,
    startLong: 20.4734,
    endLat: 44.7956,
    endLong: 20.4734,
  },
  {
    name: 'Dubljanska 50',
    startLat: 44.7956,
    startLong: 20.4735,
    endLat: 44.7956,
    endLong: 20.4735,
  },
  {
    name: 'Metohijska 36',
    startLat: 44.7931,
    startLong: 20.4933,
    endLat: 44.7931,
    endLong: 20.4933,
  },
  {
    name: 'Mihaila Gavrilovića 23',
    startLat: 44.7918,
    startLong: 20.4754,
    endLat: 44.7918,
    endLong: 20.4754,
  },
  { name: 'Cerska 20', startLat: 44.7959, startLong: 20.4794, endLat: 44.7959, endLong: 20.4794 },
  {
    name: 'Novopazarska 15-23',
    startLat: 44.7984,
    startLong: 20.4751,
    endLat: 44.7984,
    endLong: 20.475,
  },
  {
    name: 'Mihaila Gavrilovića 3-3a',
    startLat: 44.7929,
    startLong: 20.4733,
    endLat: 44.7929,
    endLong: 20.473,
  },
  {
    name: 'Edition Sazonova',
    startLat: 44.7947,
    startLong: 20.4857,
    endLat: 44.7947,
    endLong: 20.4857,
  },
  {
    name: 'Gročanska 19',
    startLat: 44.7933,
    startLong: 20.4917,
    endLat: 44.7933,
    endLong: 20.4917,
  },
  {
    name: 'Gročanska 19',
    startLat: 44.7932,
    startLong: 20.4918,
    endLat: 44.7932,
    endLong: 20.4918,
  },
  {
    name: 'Terra Residence',
    startLat: 44.7926,
    startLong: 20.493,
    endLat: 44.7925,
    endLong: 20.4928,
  },
  { name: 'Gročanska 23', startLat: 44.793, startLong: 20.4925, endLat: 44.793, endLong: 20.4925 },
  {
    name: 'Gročanska 13',
    startLat: 44.7934,
    startLong: 20.4912,
    endLat: 44.7934,
    endLong: 20.4912,
  },
  {
    name: 'Metohijska 14',
    startLat: 44.7937,
    startLong: 20.4915,
    endLat: 44.7937,
    endLong: 20.4915,
  },
  {
    name: 'Metohijska 2',
    startLat: 44.7939,
    startLong: 20.4906,
    endLat: 44.7939,
    endLong: 20.4906,
  },
  { name: 'Gročanska 29', startLat: 44.793, startLong: 20.493, endLat: 44.793, endLong: 20.493 },
  {
    name: 'Gročanska 27',
    startLat: 44.7929,
    startLong: 20.4928,
    endLat: 44.7929,
    endLong: 20.4928,
  },
  { name: 'Gročanska 5', startLat: 44.7935, startLong: 20.4905, endLat: 44.7935, endLong: 20.4905 },
  { name: 'Ksngarden', startLat: 44.7932, startLong: 20.4907, endLat: 44.7932, endLong: 20.4907 },
  {
    name: 'Metohijska 36',
    startLat: 44.7931,
    startLong: 20.4935,
    endLat: 44.7931,
    endLong: 20.4935,
  },
  {
    name: 'Gročanska 25',
    startLat: 44.7931,
    startLong: 20.4924,
    endLat: 44.7931,
    endLong: 20.4924,
  },
  {
    name: 'Gročanska 17',
    startLat: 44.7934,
    startLong: 20.4915,
    endLat: 44.7933,
    endLong: 20.4915,
  },
  {
    name: 'Ilije Garašanina 16',
    startLat: 44.8116,
    startLong: 20.4713,
    endLat: 44.8116,
    endLong: 20.4713,
  },
  { name: '27. marta 21', startLat: 44.8121, startLong: 20.474, endLat: 44.812, endLong: 20.4736 },
  {
    name: 'Ljube Didića 22',
    startLat: 44.8114,
    startLong: 20.4812,
    endLat: 44.8114,
    endLong: 20.4812,
  },
  { name: 'Primorska 31', startLat: 44.812, startLong: 20.4831, endLat: 44.812, endLong: 20.4831 },
  { name: 'Vladetina 22', startLat: 44.8094, startLong: 20.48, endLat: 44.8094, endLong: 20.48 },
  {
    name: 'Ruzveltova 19',
    startLat: 44.8072,
    startLong: 20.4798,
    endLat: 44.8071,
    endLong: 20.4798,
  },
  {
    name: 'Primorska 32',
    startLat: 44.8116,
    startLong: 20.4828,
    endLat: 44.8116,
    endLong: 20.4828,
  },
  {
    name: 'Deligradska 5',
    startLat: 44.8018,
    startLong: 20.4654,
    endLat: 44.8018,
    endLong: 20.4654,
  },
  {
    name: 'Cara Dušana 54',
    startLat: 44.8222,
    startLong: 20.4619,
    endLat: 44.8222,
    endLong: 20.4619,
  },
  { name: 'Desetka', startLat: 44.817, startLong: 20.4698, endLat: 44.817, endLong: 20.4698 },
  {
    name: 'Gospodar Jovanova 28',
    startLat: 44.821,
    startLong: 20.46,
    endLat: 44.821,
    endLong: 20.46,
  },
  {
    name: 'Knez Miletina 37',
    startLat: 44.8191,
    startLong: 20.4728,
    endLat: 44.8191,
    endLong: 20.4728,
  },
  { name: 'Dunavska 70', startLat: 44.8219, startLong: 20.4713, endLat: 44.8219, endLong: 20.4713 },
  { name: 'Ritam Dorćola', startLat: 44.8235, startLong: 20.463, endLat: 44.8235, endLong: 20.463 },
  {
    name: 'Knićaninova 5',
    startLat: 44.8237,
    startLong: 20.4622,
    endLat: 44.8237,
    endLong: 20.4622,
  },
  {
    name: 'Cara Uroša 57',
    startLat: 44.8265,
    startLong: 20.4598,
    endLat: 44.8265,
    endLong: 20.4598,
  },
  {
    name: 'Vojvode Dobrnjca 9',
    startLat: 44.8178,
    startLong: 20.4742,
    endLat: 44.8178,
    endLong: 20.4742,
  },
  {
    name: 'Kalemegdan park',
    startLat: 44.821,
    startLong: 20.4552,
    endLat: 44.821,
    endLong: 20.4552,
  },
  {
    name: 'Kapetan Mišina 39',
    startLat: 44.8234,
    startLong: 20.4633,
    endLat: 44.8234,
    endLong: 20.4633,
  },
  {
    name: 'Subotička 20',
    startLat: 44.7927,
    startLong: 20.4996,
    endLat: 44.7927,
    endLong: 20.4996,
  },
  {
    name: 'Dimitrija Tucovića 149',
    startLat: 44.8008,
    startLong: 20.4961,
    endLat: 44.8008,
    endLong: 20.4961,
  },
  {
    name: 'Ravanička 48-52',
    startLat: 44.7919,
    startLong: 20.4995,
    endLat: 44.7919,
    endLong: 20.4995,
  },
  {
    name: 'Ravanička 48-52',
    startLat: 44.7922,
    startLong: 20.4993,
    endLat: 44.7922,
    endLong: 20.4993,
  },
  {
    name: 'Ravanička 48-52',
    startLat: 44.7922,
    startLong: 20.4992,
    endLat: 44.7922,
    endLong: 20.4992,
  },
  {
    name: 'Ravanička 48-52',
    startLat: 44.7921,
    startLong: 20.4993,
    endLat: 44.7921,
    endLong: 20.4993,
  },
  {
    name: 'Ravanička 48-52',
    startLat: 44.7923,
    startLong: 20.4991,
    endLat: 44.7923,
    endLong: 20.4991,
  },
  {
    name: 'Ravanička 48-52',
    startLat: 44.792,
    startLong: 20.4994,
    endLat: 44.792,
    endLong: 20.4994,
  },
  {
    name: 'Bulevar kralja Aleksandra 444',
    startLat: 44.7893,
    startLong: 20.5155,
    endLat: 44.7893,
    endLong: 20.5155,
  },
  {
    name: 'Milana Rakića 118',
    startLat: 44.792,
    startLong: 20.5179,
    endLat: 44.792,
    endLong: 20.5179,
  },
  { name: 'Krfska 18', startLat: 44.8031, startLong: 20.4917, endLat: 44.8031, endLong: 20.4917 },
  { name: 'Donska 10', startLat: 44.7915, startLong: 20.5043, endLat: 44.7915, endLong: 20.5043 },
  {
    name: 'Podujevska 14',
    startLat: 44.7908,
    startLong: 20.5094,
    endLat: 44.7908,
    endLong: 20.5094,
  },
  {
    name: 'Podujevska 16-18',
    startLat: 44.7907,
    startLong: 20.5096,
    endLat: 44.7907,
    endLong: 20.5096,
  },
  {
    name: 'Generala Aračića 13',
    startLat: 44.8149,
    startLong: 20.4975,
    endLat: 44.8149,
    endLong: 20.4974,
  },
  {
    name: 'Bore Prodanovića 15',
    startLat: 44.8065,
    startLong: 20.495,
    endLat: 44.8065,
    endLong: 20.495,
  },
  { name: 'Krfska 22', startLat: 44.8033, startLong: 20.492, endLat: 44.8033, endLong: 20.492 },
  {
    name: 'Svetog Nikole 158',
    startLat: 44.802,
    startLong: 20.4977,
    endLat: 44.802,
    endLong: 20.4977,
  },
  {
    name: 'Svetog Nikole 148',
    startLat: 44.8029,
    startLong: 20.4961,
    endLat: 44.8029,
    endLong: 20.496,
  },
  {
    name: 'Vojvode Sime Popovića 18',
    startLat: 44.7998,
    startLong: 20.5081,
    endLat: 44.7998,
    endLong: 20.5081,
  },
  {
    name: 'Vojislava Ilića 55',
    startLat: 44.7935,
    startLong: 20.4956,
    endLat: 44.7935,
    endLong: 20.4956,
  },
  {
    name: 'Vojislava Ilića 73',
    startLat: 44.7923,
    startLong: 20.4971,
    endLat: 44.7923,
    endLong: 20.4971,
  },
  { name: 'Livanjska 1', startLat: 44.7886, startLong: 20.5155, endLat: 44.7884, endLong: 20.5154 },
  {
    name: 'Lošinjska 34-38',
    startLat: 44.7879,
    startLong: 20.5217,
    endLat: 44.7879,
    endLong: 20.5217,
  },
  {
    name: 'Elixir Zvezdara',
    startLat: 44.7919,
    startLong: 20.5167,
    endLat: 44.7919,
    endLong: 20.5167,
  },
  {
    name: 'Stjepana Ljubiše 31',
    startLat: 44.8068,
    startLong: 20.4839,
    endLat: 44.8068,
    endLong: 20.4839,
  },
  {
    name: 'Dr Velizara Kosanovića 56',
    startLat: 44.788,
    startLong: 20.5057,
    endLat: 44.788,
    endLong: 20.5057,
  },
  { name: 'Krfska 24', startLat: 44.8036, startLong: 20.4922, endLat: 44.8036, endLong: 20.4922 },
  { name: 'Golubačka 4', startLat: 44.7975, startLong: 20.4884, endLat: 44.7975, endLong: 20.4884 },
  { name: 'Čelopečka 2', startLat: 44.8052, startLong: 20.4798, endLat: 44.8052, endLong: 20.4798 },
  {
    name: 'Slavujev venac 15',
    startLat: 44.8063,
    startLong: 20.486,
    endLat: 44.8063,
    endLong: 20.486,
  },
  {
    name: 'Luneta Milovanovića 1',
    startLat: 44.7853,
    startLong: 20.5169,
    endLat: 44.7853,
    endLong: 20.5169,
  },
  {
    name: 'Olge Jovanović 16',
    startLat: 44.7919,
    startLong: 20.5045,
    endLat: 44.7919,
    endLong: 20.5045,
  },
  {
    name: 'Vojislava Ilića 13',
    startLat: 44.7952,
    startLong: 20.491,
    endLat: 44.7952,
    endLong: 20.491,
  },
  {
    name: 'Paje Jovanovića 11',
    startLat: 44.7892,
    startLong: 20.5082,
    endLat: 44.7892,
    endLong: 20.5082,
  },
  {
    name: 'Učiteljska 11',
    startLat: 44.7879,
    startLong: 20.5128,
    endLat: 44.7879,
    endLong: 20.5128,
  },
  {
    name: 'Bulevar Plaza',
    startLat: 44.8007,
    startLong: 20.4875,
    endLat: 44.8007,
    endLong: 20.4873,
  },
  {
    name: 'Svetolika Rankovića 4',
    startLat: 44.7954,
    startLong: 20.4813,
    endLat: 44.7954,
    endLong: 20.4813,
  },
  {
    name: 'Kneginje Zorke 38',
    startLat: 44.8013,
    startLong: 20.4703,
    endLat: 44.8013,
    endLong: 20.4703,
  },
  {
    name: 'Šumatovačka 157-159',
    startLat: 44.7932,
    startLong: 20.4867,
    endLat: 44.7932,
    endLong: 20.4865,
  },
  {
    name: 'Bulevar oslobođenja 31',
    startLat: 44.7996,
    startLong: 20.4662,
    endLat: 44.7996,
    endLong: 20.4661,
  },
  { name: 'Sazonova 91', startLat: 44.7945, startLong: 20.4871, endLat: 44.7945, endLong: 20.4871 },
  {
    name: 'Novopazarska 16',
    startLat: 44.7983,
    startLong: 20.4752,
    endLat: 44.7982,
    endLong: 20.4752,
  },
  { name: 'Cerska 100', startLat: 44.7937, startLong: 20.4867, endLat: 44.7937, endLong: 20.4867 },
  { name: 'Cerska 100', startLat: 44.7936, startLong: 20.4869, endLat: 44.7936, endLong: 20.4869 },
  { name: 'Cerska 70', startLat: 44.7948, startLong: 20.4838, endLat: 44.7948, endLong: 20.4838 },
  { name: 'Cerska 121', startLat: 44.7937, startLong: 20.4886, endLat: 44.7937, endLong: 20.4886 },
  { name: 'Sazonova 87', startLat: 44.7945, startLong: 20.4869, endLat: 44.7945, endLong: 20.4869 },
  {
    name: 'Loznička 8-10',
    startLat: 44.7999,
    startLong: 20.4816,
    endLat: 44.7999,
    endLong: 20.4816,
  },
  {
    name: 'Šumatovačka 139',
    startLat: 44.7939,
    startLong: 20.4848,
    endLat: 44.7939,
    endLong: 20.4848,
  },
  {
    name: 'Vojvode Hrvoja 21',
    startLat: 44.7915,
    startLong: 20.4754,
    endLat: 44.7915,
    endLong: 20.4754,
  },
  {
    name: 'Mlade Bosne 32',
    startLat: 44.7925,
    startLong: 20.4821,
    endLat: 44.7925,
    endLong: 20.4821,
  },
  {
    name: 'Černiševskog 6',
    startLat: 44.7965,
    startLong: 20.4757,
    endLat: 44.7965,
    endLong: 20.4757,
  },
  {
    name: 'Sazonova 55-57',
    startLat: 44.7957,
    startLong: 20.4833,
    endLat: 44.7957,
    endLong: 20.4832,
  },
  { name: 'Cerska 107', startLat: 44.7939, startLong: 20.4867, endLat: 44.7939, endLong: 20.4867 },
  {
    name: 'Šumatovačka 87',
    startLat: 44.7952,
    startLong: 20.4807,
    endLat: 44.7952,
    endLong: 20.4807,
  },
  {
    name: 'Maksima Gorkog 36',
    startLat: 44.7937,
    startLong: 20.4777,
    endLat: 44.7937,
    endLong: 20.4777,
  },
  {
    name: 'Dubljanska 5',
    startLat: 44.7961,
    startLong: 20.4698,
    endLat: 44.7961,
    endLong: 20.4698,
  },
  {
    name: 'Lamartinova 49',
    startLat: 44.7935,
    startLong: 20.4719,
    endLat: 44.7935,
    endLong: 20.4719,
  },
  { name: 'Cerska 71', startLat: 44.7949, startLong: 20.4839, endLat: 44.7949, endLong: 20.4839 },
  {
    name: 'Gružanska 28',
    startLat: 44.7962,
    startLong: 20.4886,
    endLat: 44.7962,
    endLong: 20.4886,
  },
  {
    name: 'Mihaila Gavrilovića 21',
    startLat: 44.7919,
    startLong: 20.4753,
    endLat: 44.7919,
    endLong: 20.4752,
  },
  {
    name: 'Nikolaja Hartviga 9',
    startLat: 44.7961,
    startLong: 20.476,
    endLat: 44.7961,
    endLong: 20.476,
  },
  {
    name: 'RG Residence',
    startLat: 44.8003,
    startLong: 20.4806,
    endLat: 44.8003,
    endLong: 20.4806,
  },
  {
    name: 'Šumatovačka 72',
    startLat: 44.7952,
    startLong: 20.4748,
    endLat: 44.7952,
    endLong: 20.4748,
  },
  {
    name: 'Vojvode Hrvoja 33',
    startLat: 44.7914,
    startLong: 20.4743,
    endLat: 44.7914,
    endLong: 20.4743,
  },
  {
    name: 'Gospodara Vučića 67',
    startLat: 44.7917,
    startLong: 20.4788,
    endLat: 44.7916,
    endLong: 20.4787,
  },
  {
    name: 'Gospodara Vučića 173',
    startLat: 44.7913,
    startLong: 20.4919,
    endLat: 44.7913,
    endLong: 20.4919,
  },
  { name: 'Cerska 78', startLat: 44.7945, startLong: 20.4844, endLat: 44.7945, endLong: 20.4844 },
  { name: 'Cerska 45', startLat: 44.7957, startLong: 20.482, endLat: 44.7957, endLong: 20.482 },
  { name: 'Ljubostinjska 5', startLat: 44.8021, startLong: 20.48, endLat: 44.8021, endLong: 20.48 },
  {
    name: 'Gospodara Vučića 41',
    startLat: 44.7913,
    startLong: 20.4762,
    endLat: 44.7913,
    endLong: 20.4762,
  },
  { name: 'Trnska 6', startLat: 44.8017, startLong: 20.4765, endLat: 44.8017, endLong: 20.4765 },
  { name: 'Vardarska 4', startLat: 44.7961, startLong: 20.4797, endLat: 44.7961, endLong: 20.4797 },
  {
    name: 'Novopazarska 46',
    startLat: 44.7985,
    startLong: 20.4776,
    endLat: 44.7985,
    endLong: 20.4776,
  },
  { name: 'Cerska 22', startLat: 44.7959, startLong: 20.4796, endLat: 44.7959, endLong: 20.4796 },
  {
    name: 'Južni bulevar 5',
    startLat: 44.7923,
    startLong: 20.471,
    endLat: 44.7923,
    endLong: 20.471,
  },
  {
    name: 'Dubljanska 37',
    startLat: 44.7958,
    startLong: 20.4728,
    endLat: 44.7958,
    endLong: 20.4728,
  },
  { name: 'Jadranska 4', startLat: 44.7936, startLong: 20.4871, endLat: 44.7936, endLong: 20.4871 },
  { name: 'Jadranska 6', startLat: 44.7935, startLong: 20.4872, endLat: 44.7935, endLong: 20.4872 },
  {
    name: 'Južni bulevar 25',
    startLat: 44.7929,
    startLong: 20.4737,
    endLat: 44.7929,
    endLong: 20.4737,
  },
  {
    name: 'Stojana Protića 9',
    startLat: 44.7968,
    startLong: 20.4705,
    endLat: 44.7968,
    endLong: 20.4705,
  },
  {
    name: 'Sime Igumanova 25',
    startLat: 44.7922,
    startLong: 20.4753,
    endLat: 44.7922,
    endLong: 20.4753,
  },
  {
    name: 'Internacionalnih brigada 93',
    startLat: 44.7949,
    startLong: 20.4768,
    endLat: 44.7949,
    endLong: 20.4768,
  },
  {
    name: 'Cara Nikolaja II 1',
    startLat: 44.7977,
    startLong: 20.4748,
    endLat: 44.7977,
    endLong: 20.4748,
  },
  {
    name: 'Cara Nikolaja II 72',
    startLat: 44.798,
    startLong: 20.4822,
    endLat: 44.798,
    endLong: 20.4822,
  },
  {
    name: 'Vojvode Hrvoja 7',
    startLat: 44.7917,
    startLong: 20.4769,
    endLat: 44.7917,
    endLong: 20.4769,
  },
  {
    name: 'Bulevar kralja Aleksandra 114',
    startLat: 44.8043,
    startLong: 20.4776,
    endLat: 44.8043,
    endLong: 20.4776,
  },
  {
    name: 'Južni bulevar 86',
    startLat: 44.7934,
    startLong: 20.4798,
    endLat: 44.7934,
    endLong: 20.4798,
  },
  { name: 'Cerska 61', startLat: 44.7953, startLong: 20.4832, endLat: 44.7953, endLong: 20.4832 },
  { name: 'Cerska 61', startLat: 44.7952, startLong: 20.4833, endLat: 44.7952, endLong: 20.4833 },
  {
    name: 'ML 27 Swiss apartments',
    startLat: 44.7929,
    startLong: 20.4739,
    endLat: 44.7929,
    endLong: 20.4739,
  },
  {
    name: 'Gospodara Vučića 35',
    startLat: 44.7919,
    startLong: 20.4822,
    endLat: 44.7918,
    endLong: 20.4822,
  },
  {
    name: 'Vardarska 16',
    startLat: 44.7946,
    startLong: 20.4795,
    endLat: 44.7946,
    endLong: 20.4795,
  },
  {
    name: 'Vojvode Hrvoja 19',
    startLat: 44.7915,
    startLong: 20.476,
    endLat: 44.7915,
    endLong: 20.476,
  },
  {
    name: 'Vojvode Hrvoja 15-17',
    startLat: 44.7916,
    startLong: 20.4763,
    endLat: 44.7916,
    endLong: 20.4763,
  },
  {
    name: 'Zelena avenija',
    startLat: 44.838,
    startLong: 20.4043,
    endLat: 44.8373,
    endLong: 20.4032,
  },
  {
    name: 'Zelena avenija 2',
    startLat: 44.8363,
    startLong: 20.4037,
    endLat: 44.8363,
    endLong: 20.4037,
  },
  {
    name: 'Zelena avenija 2',
    startLat: 44.8362,
    startLong: 20.4035,
    endLat: 44.8362,
    endLong: 20.4035,
  },
  {
    name: 'Prvomajska 59',
    startLat: 44.8411,
    startLong: 20.3884,
    endLat: 44.8411,
    endLong: 20.3884,
  },
  { name: 'Prvomajska 59', startLat: 44.8414, startLong: 20.3885, endLat: 44.8414, endLong: 20.3885 },
  {
    name: 'Taurunum tandem',
    startLat: 44.8524,
    startLong: 20.3955,
    endLat: 44.8524,
    endLong: 20.3955,
  },
  {
    name: 'Edition Nodilova',
    startLat: 44.7842,
    startLong: 20.423,
    endLat: 44.7842,
    endLong: 20.423,
  },
  { name: 'Singa', startLat: 44.78, startLong: 20.408, endLat: 44.78, endLong: 20.408 },
  {
    name: 'Paštrovićeva 7',
    startLat: 44.7847,
    startLong: 20.4259,
    endLat: 44.7847,
    endLong: 20.4259,
  },
  {
    name: 'Grge Jankesa 6',
    startLat: 44.7921,
    startLong: 20.5307,
    endLat: 44.7921,
    endLong: 20.5307,
  },
  { name: 'Maleška 5', startLat: 44.7939, startLong: 20.4574, endLat: 44.7939, endLong: 20.4574 },
  { name: 'Banijska 60', startLat: 44.8474, startLong: 20.3847, endLat: 44.8474, endLong: 20.3847 },
  {
    name: 'Majdanska 20',
    startLat: 44.7858,
    startLong: 20.4192,
    endLat: 44.7858,
    endLong: 20.4192,
  },
  {
    name: 'Šumadijske Divizije',
    startLat: 44.7609,
    startLong: 20.4884,
    endLat: 44.7609,
    endLong: 20.4884,
  },
  { name: 'Dinarska 2', startLat: 44.7909, startLong: 20.4641, endLat: 44.7909, endLong: 20.4641 },
  {
    name: 'Dobropoljska 70',
    startLat: 44.7931,
    startLong: 20.4614,
    endLat: 44.7931,
    endLong: 20.4614,
  },
  {
    name: 'Josipa Slavenskog',
    startLat: 44.754,
    startLong: 20.4727,
    endLat: 44.754,
    endLong: 20.4727,
  },
  { name: 'V Kvart', startLat: 44.7623, startLong: 20.4998, endLat: 44.7622, endLong: 20.4997 },
  {
    name: 'Železnička 37',
    startLat: 44.8388,
    startLong: 20.3851,
    endLat: 44.8388,
    endLong: 20.3851,
  },
  {
    name: 'Kralja Milutina 54',
    startLat: 44.8002,
    startLong: 20.4615,
    endLat: 44.8002,
    endLong: 20.4615,
  },
  {
    name: 'Kralja Milutina 54',
    startLat: 44.8002,
    startLong: 20.4612,
    endLat: 44.8002,
    endLong: 20.4612,
  },
  {
    name: 'Dobropoljska 62',
    startLat: 44.7928,
    startLong: 20.4619,
    endLat: 44.7928,
    endLong: 20.4619,
  },
  {
    name: 'Gospodara Vučića 59',
    startLat: 44.7914,
    startLong: 20.4781,
    endLat: 44.7914,
    endLong: 20.4781,
  },
  { name: 'Požeška 31', startLat: 44.7829, startLong: 20.4215, endLat: 44.7829, endLong: 20.4215 },
  { name: 'Požeška 33', startLat: 44.7827, startLong: 20.4213, endLat: 44.7827, endLong: 20.4213 },
  { name: 'Alaska 5', startLat: 44.853, startLong: 20.3886, endLat: 44.8525, endLong: 20.3886 },
  {
    name: 'Cara Dušana 122',
    startLat: 44.8521,
    startLong: 20.3953,
    endLat: 44.8521,
    endLong: 20.3953,
  },
  {
    name: 'Slavonska 15',
    startLat: 44.8511,
    startLong: 20.3954,
    endLat: 44.8511,
    endLong: 20.3954,
  },
  {
    name: 'Milorada Miškovića 37',
    startLat: 44.7518,
    startLong: 20.4759,
    endLat: 44.7518,
    endLong: 20.4755,
  },
  {
    name: 'Kapetana Miloša Žunjića 37',
    startLat: 44.7964,
    startLong: 20.541,
    endLat: 44.7954,
    endLong: 20.5397,
  },
  {
    name: 'Pavla Vujevića 9',
    startLat: 44.7247,
    startLong: 20.4311,
    endLat: 44.7247,
    endLong: 20.4311,
  },
  {
    name: 'Mirijevski bulevar 86',
    startLat: 44.8013,
    startLong: 20.5268,
    endLat: 44.8013,
    endLong: 20.5268,
  },
  {
    name: 'Dragoslava Đorđevića-Goše 18',
    startLat: 44.7963,
    startLong: 20.53,
    endLat: 44.7963,
    endLong: 20.53,
  },
  {
    name: 'Milorada Jovanovića 33',
    startLat: 44.7569,
    startLong: 20.4039,
    endLat: 44.7569,
    endLong: 20.4039,
  },
  { name: 'Danilova 3', startLat: 44.7952, startLong: 20.5256, endLat: 44.7952, endLong: 20.5256 },
  {
    name: 'Železnička 15',
    startLat: 44.8405,
    startLong: 20.3861,
    endLat: 44.8405,
    endLong: 20.3861,
  },
  {
    name: 'Nedeljka Gvozdenovića 22',
    startLat: 44.8124,
    startLong: 20.3705,
    endLat: 44.8124,
    endLong: 20.3705,
  },
  {
    name: 'Branka Pešića 23',
    startLat: 44.8517,
    startLong: 20.3934,
    endLat: 44.8517,
    endLong: 20.3934,
  },
  {
    name: 'Stolarska 13',
    startLat: 44.8054,
    startLong: 20.3553,
    endLat: 44.8054,
    endLong: 20.3553,
  },
  {
    name: 'Dragačevska 3a',
    startLat: 44.7901,
    startLong: 20.4926,
    endLat: 44.7901,
    endLong: 20.4926,
  },
  {
    name: 'Bože Jankovića 8',
    startLat: 44.7835,
    startLong: 20.4714,
    endLat: 44.7835,
    endLong: 20.4714,
  },
  {
    name: 'Milice Janković 1',
    startLat: 44.8156,
    startLong: 20.4984,
    endLat: 44.8156,
    endLong: 20.4984,
  },
  {
    name: 'Nikolaja Gogolja 38d',
    startLat: 44.7766,
    startLong: 20.4126,
    endLat: 44.7766,
    endLong: 20.4126,
  },
  {
    name: 'Marine Veličković 21',
    startLat: 44.7646,
    startLong: 20.4086,
    endLat: 44.7646,
    endLong: 20.4086,
  },
  {
    name: 'Cvetanova Ćuprija',
    startLat: 44.7676,
    startLong: 20.5165,
    endLat: 44.7676,
    endLong: 20.5165,
  },
  { name: 'Banijska 68', startLat: 44.848, startLong: 20.3849, endLat: 44.848, endLong: 20.3849 },
  {
    name: 'Ugrinovačka 217',
    startLat: 44.849,
    startLong: 20.3785,
    endLat: 44.849,
    endLong: 20.3785,
  },
  {
    name: 'Zemun Garden',
    startLat: 44.8393,
    startLong: 20.3806,
    endLat: 44.8393,
    endLong: 20.3806,
  },
  { name: 'Zemun Garden', startLat: 44.8398, startLong: 20.381, endLat: 44.8398, endLong: 20.381 },
  {
    name: 'Zemun Garden',
    startLat: 44.8398,
    startLong: 20.3812,
    endLat: 44.8398,
    endLong: 20.3812,
  },
  {
    name: 'Breskvin cvet',
    startLat: 44.8368,
    startLong: 20.3881,
    endLat: 44.8368,
    endLong: 20.3881,
  },
  { name: 'Nodilova 15', startLat: 44.7844, startLong: 20.423, endLat: 44.7844, endLong: 20.4229 },
  {
    name: 'Pregrevica Lux',
    startLat: 44.8512,
    startLong: 20.4021,
    endLat: 44.8512,
    endLong: 20.4021,
  },
  {
    name: 'Tršćanska 62',
    startLat: 44.8504,
    startLong: 20.3903,
    endLat: 44.8504,
    endLong: 20.3903,
  },
  {
    name: 'Crnogorska 16',
    startLat: 44.8433,
    startLong: 20.3954,
    endLat: 44.8433,
    endLong: 20.3954,
  },
  {
    name: 'Živka Petrovića 39',
    startLat: 44.8384,
    startLong: 20.3874,
    endLat: 44.8384,
    endLong: 20.3874,
  },
  {
    name: 'Square Residence',
    startLat: 44.8506,
    startLong: 20.3773,
    endLat: 44.8504,
    endLong: 20.3765,
  },
  {
    name: 'Kalvaria Residence',
    startLat: 44.84,
    startLong: 20.4024,
    endLat: 44.84,
    endLong: 20.4024,
  },
  {
    name: 'Ugrinovačka 152',
    startLat: 44.8503,
    startLong: 20.3845,
    endLat: 44.8503,
    endLong: 20.3845,
  },
  { name: 'Bosanska 57', startLat: 44.8471, startLong: 20.3894, endLat: 44.8471, endLong: 20.3894 },
  {
    name: 'Gundulićeva 8',
    startLat: 44.8438,
    startLong: 20.4085,
    endLat: 44.8438,
    endLong: 20.4085,
  },
  {
    name: 'Veselina Masleše 1',
    startLat: 44.7916,
    startLong: 20.4861,
    endLat: 44.7916,
    endLong: 20.4861,
  },
  {
    name: 'Danijelova 23',
    startLat: 44.7832,
    startLong: 20.4785,
    endLat: 44.7832,
    endLong: 20.4785,
  },
  {
    name: 'Đorđa Kratovca 5',
    startLat: 44.7883,
    startLong: 20.4807,
    endLat: 44.7883,
    endLong: 20.4807,
  },
  {
    name: 'Milovana Marinkovića 1',
    startLat: 44.7832,
    startLong: 20.4678,
    endLat: 44.7832,
    endLong: 20.4678,
  },
  {
    name: 'Koste Jovanovića 25',
    startLat: 44.7818,
    startLong: 20.4695,
    endLat: 44.7818,
    endLong: 20.4695,
  },
  { name: 'Tikveška 3', startLat: 44.7915, startLong: 20.482, endLat: 44.7915, endLong: 20.482 },
  {
    name: 'Jove Ilića 116',
    startLat: 44.7767,
    startLong: 20.4735,
    endLat: 44.7767,
    endLong: 20.4735,
  },
  {
    name: 'Admirala Vukovića 42',
    startLat: 44.7848,
    startLong: 20.4728,
    endLat: 44.7848,
    endLong: 20.4728,
  },
  {
    name: 'Koste Jovanovića 35',
    startLat: 44.7812,
    startLong: 20.4699,
    endLat: 44.7812,
    endLong: 20.4699,
  },
  {
    name: 'Stevana Lilića 7',
    startLat: 44.7844,
    startLong: 20.472,
    endLat: 20.472,
    endLong: 20.472,
  },
  { name: 'Tetovska 36', startLat: 44.7896, startLong: 20.483, endLat: 44.7896, endLong: 20.483 },
  {
    name: 'Strumička 20',
    startLat: 44.7886,
    startLong: 20.4825,
    endLat: 44.7886,
    endLong: 20.4825,
  },
  {
    name: 'Admirala Vukovića 58',
    startLat: 44.7843,
    startLong: 20.474,
    endLat: 44.7843,
    endLong: 20.474,
  },
  {
    name: 'Borisavljevićeva 4',
    startLat: 44.7847,
    startLong: 20.4697,
    endLat: 44.7847,
    endLong: 20.4697,
  },
  {
    name: 'Bulevar oslobođenja 159',
    startLat: 44.778,
    startLong: 20.4712,
    endLat: 44.778,
    endLong: 20.4712,
  },
  { name: 'Tikveška 28', startLat: 44.7891, startLong: 20.4821, endLat: 44.7891, endLong: 20.4821 },
  {
    name: 'Milice Milojković 17',
    startLat: 44.7635,
    startLong: 20.4808,
    endLat: 44.7635,
    endLong: 20.4808,
  },
  {
    name: 'Rade Končara 17',
    startLat: 44.7899,
    startLong: 20.4781,
    endLat: 44.7899,
    endLong: 20.4781,
  },
  {
    name: 'Đorđa Kratovca 61',
    startLat: 44.7862,
    startLong: 20.4862,
    endLat: 44.7862,
    endLong: 20.4862,
  },
  {
    name: 'Vojvode Prijezde 23',
    startLat: 44.789,
    startLong: 20.4764,
    endLat: 44.789,
    endLong: 20.4764,
  },
  {
    name: 'Đevđelijska 69',
    startLat: 44.7992,
    startLong: 20.4943,
    endLat: 44.7992,
    endLong: 20.4943,
  },
  {
    name: 'Đevđelijska 17',
    startLat: 44.8006,
    startLong: 20.4891,
    endLat: 44.8006,
    endLong: 20.4891,
  },
  {
    name: 'Kajmakčalanska 37',
    startLat: 44.7985,
    startLong: 20.4887,
    endLat: 44.7985,
    endLong: 20.4887,
  },
  { name: 'Hotel Xenon', startLat: 44.7929, startLong: 20.4965, endLat: 44.7929, endLong: 20.4965 },
  { name: 'Žička 3', startLat: 44.7975, startLong: 20.4876, endLat: 44.7975, endLong: 20.4876 },
  {
    name: 'Slobodanke-Danke Savić 21',
    startLat: 44.7937,
    startLong: 20.5015,
    endLat: 44.7937,
    endLong: 20.5015,
  },
  {
    name: 'Beogradska 21',
    startLat: 44.8047,
    startLong: 20.4685,
    endLat: 44.8047,
    endLong: 20.4685,
  },
  {
    name: 'Gospodara Vučića 75',
    startLat: 44.7918,
    startLong: 20.4801,
    endLat: 44.7918,
    endLong: 20.4801,
  },
  {
    name: 'Preševska 50',
    startLat: 44.7993,
    startLong: 20.4955,
    endLat: 44.7993,
    endLong: 20.4955,
  },
  {
    name: 'Majke Kujundžić 17',
    startLat: 44.7915,
    startLong: 20.5071,
    endLat: 44.7915,
    endLong: 20.5071,
  },
  {
    name: 'Paje Jovanovića 32',
    startLat: 44.7897,
    startLong: 20.5078,
    endLat: 44.7897,
    endLong: 20.5078,
  },
  {
    name: 'WoodStar Residence',
    startLat: 44.8116,
    startLong: 20.4956,
    endLat: 44.8112,
    endLong: 20.4955,
  },
  { name: 'Ozrenska 13', startLat: 44.7843, startLong: 20.4838, endLat: 44.7843, endLong: 20.4838 },
  { name: 'Ozrenska 13', startLat: 44.7843, startLong: 20.4839, endLat: 44.7843, endLong: 20.4839 },
  { name: 'Ozrenska 17', startLat: 44.7842, startLong: 20.4841, endLat: 44.7842, endLong: 20.4841 },
  {
    name: 'Kralja Bodina 12',
    startLat: 44.7888,
    startLong: 20.4863,
    endLat: 44.7888,
    endLong: 20.4863,
  },
  {
    name: 'Kralja Bodina 12',
    startLat: 44.7888,
    startLong: 20.4865,
    endLat: 44.7888,
    endLong: 20.4865,
  },
  {
    name: 'Kralja Bodina 12',
    startLat: 44.7887,
    startLong: 20.4862,
    endLat: 44.7887,
    endLong: 20.4862,
  },
  {
    name: 'Miloša Svilara 22',
    startLat: 44.7884,
    startLong: 20.4968,
    endLat: 44.7884,
    endLong: 20.4968,
  },
  {
    name: 'Vanđela Tome 3',
    startLat: 44.7769,
    startLong: 20.4881,
    endLat: 44.7769,
    endLong: 20.4881,
  },
  {
    name: 'Černiševskog 12',
    startLat: 44.7965,
    startLong: 20.4761,
    endLat: 44.7965,
    endLong: 20.4761,
  },
  {
    name: 'Royale Art Residence & Spa',
    startLat: 44.8166,
    startLong: 20.4512,
    endLat: 44.8166,
    endLong: 20.4512,
  },
  {
    name: 'Royale Art Residence & Spa',
    startLat: 44.8165,
    startLong: 20.4512,
    endLat: 44.8165,
    endLong: 20.4512,
  },
  {
    name: 'Deligradska 5',
    startLat: 44.8016,
    startLong: 20.4654,
    endLat: 44.8016,
    endLong: 20.4654,
  },
  {
    name: 'Svetozara Miletića 19',
    startLat: 44.8218,
    startLong: 20.4719,
    endLat: 44.8218,
    endLong: 20.4719,
  },
  {
    name: 'Jastrebovljeva 7',
    startLat: 44.7815,
    startLong: 20.4153,
    endLat: 44.7815,
    endLong: 20.4153,
  },
  { name: 'Šekspirova 29', startLat: 44.7821, startLong: 20.46, endLat: 44.7821, endLong: 20.46 },
  { name: 'VP10', startLat: 44.7905, startLong: 20.4769, endLat: 44.7905, endLong: 20.4769 },
  {
    name: 'Kralja Vladimira 16',
    startLat: 44.7813,
    startLong: 20.4732,
    endLat: 44.7813,
    endLong: 20.4732,
  },
  { name: 'Osiječka 19', startLat: 44.7735, startLong: 20.4125, endLat: 44.7735, endLong: 20.4125 },
  { name: 'Oblakovska 11', startLat: 44.791, startLong: 20.4658, endLat: 44.791, endLong: 20.4658 },
  {
    name: 'Narodnog Fronta 12',
    startLat: 44.7813,
    startLong: 20.5223,
    endLat: 44.7813,
    endLong: 20.5223,
  },
  {
    name: 'Rade Končara 39',
    startLat: 44.7898,
    startLong: 20.4753,
    endLat: 44.7898,
    endLong: 20.4753,
  },
  {
    name: 'Pregrevica 69',
    startLat: 44.8535,
    startLong: 20.3949,
    endLat: 44.8535,
    endLong: 20.3949,
  },
  { name: 'Zemun Vista', startLat: 44.8523, startLong: 20.3932, endLat: 44.8522, endLong: 20.3929 },
  { name: 'Banatska 14', startLat: 44.8509, startLong: 20.3796, endLat: 44.8509, endLong: 20.3796 },
  {
    name: 'Ugrinovačka 98',
    startLat: 44.8484,
    startLong: 20.3946,
    endLat: 44.8484,
    endLong: 20.3946,
  },
  { name: 'Cerska 77', startLat: 44.7947, startLong: 20.4844, endLat: 44.7947, endLong: 20.4844 },
  {
    name: 'Smart Home Voždovac',
    startLat: 44.7735,
    startLong: 20.4824,
    endLat: 44.7731,
    endLong: 20.4822,
  },
  {
    name: 'Nikolaja Hartviga 14',
    startLat: 44.7959,
    startLong: 20.476,
    endLat: 44.7959,
    endLong: 20.476,
  },
  {
    name: 'Jasenička 19',
    startLat: 44.7877,
    startLong: 20.4972,
    endLat: 44.7877,
    endLong: 20.4972,
  },
  { name: 'Šilerova 79', startLat: 44.8481, startLong: 20.3874, endLat: 44.8481, endLong: 20.3874 },
  {
    name: 'Taurunum XXI',
    startLat: 44.8379,
    startLong: 20.4149,
    endLat: 44.8379,
    endLong: 20.4149,
  },
  { name: 'Ruska 6', startLat: 44.8572, startLong: 20.3806, endLat: 44.8572, endLong: 20.3806 },
  {
    name: 'Stevana Lilića 7',
    startLat: 44.7844,
    startLong: 20.4719,
    endLat: 44.7844,
    endLong: 20.4719,
  },
  {
    name: 'Dr. Nedeljka Ercegovca 6',
    startLat: 44.8399,
    startLong: 20.4008,
    endLat: 44.8399,
    endLong: 20.4008,
  },
  {
    name: 'Atanasija Pulje 21',
    startLat: 44.8357,
    startLong: 20.3796,
    endLat: 44.8357,
    endLong: 20.3796,
  },
  {
    name: 'Starca Vujadina 8-10',
    startLat: 44.8371,
    startLong: 20.4138,
    endLat: 44.8371,
    endLong: 20.4138,
  },
  {
    name: 'Jove Ilića 49',
    startLat: 44.7827,
    startLong: 20.4699,
    endLat: 44.7827,
    endLong: 20.4699,
  },
  { name: 'K Blok', startLat: 44.7546, startLong: 20.4971, endLat: 44.7546, endLong: 20.4971 },
  { name: 'K Blok', startLat: 44.7544, startLong: 20.4974, endLat: 44.7544, endLong: 20.4974 },
  {
    name: 'BW Nika, Eden, Iris i Thalia',
    startLat: 44.8078,
    startLong: 20.4534,
    endLat: 44.8078,
    endLong: 20.4534,
  },
  { name: 'BW', startLat: 44.8077, startLong: 20.4524, endLat: 44.8077, endLong: 20.4524 },
  {
    name: 'Mačvanska 26',
    startLat: 44.7978,
    startLong: 20.4717,
    endLat: 44.7978,
    endLong: 20.4717,
  },
  {
    name: 'Viva Residences',
    startLat: 44.796,
    startLong: 20.5057,
    endLat: 44.796,
    endLong: 20.5057,
  },
  {
    name: 'Viva Residences',
    startLat: 44.7978,
    startLong: 20.5,
    endLat: 44.7978,
    endLong: 20.5,
  },
  {
    name: 'Viva Residences',
    startLat: 44.7981,
    startLong: 20.4996,
    endLat: 44.7981,
    endLong: 20.4996,
  },
  {
    name: 'Viva Residences',
    startLat: 44.7976,
    startLong: 20.4999,
    endLat: 44.7976,
    endLong: 20.4999,
  },
  {
    name: 'Viva Residences',
    startLat: 44.7979,
    startLong: 20.4999,
    endLat: 44.7979,
    endLong: 20.4999,
  },
  {
    name: 'Internacionalnih brigada 48',
    startLat: 44.7946,
    startLong: 20.4724,
    endLat: 44.7946,
    endLong: 20.4724,
  },
  { name: 'Krfska 10', startLat: 44.8027, startLong: 20.4913, endLat: 44.8027, endLong: 20.4913 },
  {
    name: 'Rada Neimara 21',
    startLat: 44.7888,
    startLong: 20.4912,
    endLat: 44.7888,
    endLong: 20.4912,
  },
  {
    name: 'Heroja Milana Tepića 6',
    startLat: 44.7779,
    startLong: 20.4596,
    endLat: 44.7779,
    endLong: 20.4596,
  },
  {
    name: 'Heroja Milana Tepića 6',
    startLat: 44.7778,
    startLong: 20.4594,
    endLat: 44.7778,
    endLong: 20.4594,
  },
  { name: 'Heroja Milana Tepića 6', startLat: 44.7779, startLong: 20.4595, endLat: 44.7779, endLong: 20.4595 },
  {
    name: 'Požeška 31-31a',
    startLat: 44.7827,
    startLong: 20.4216,
    endLat: 44.7827,
    endLong: 20.4216,
  },
  {
    name: 'Karpoševa 30',
    startLat: 44.7846,
    startLong: 20.4155,
    endLat: 44.7846,
    endLong: 20.4155,
  },
  {
    name: 'Mike Alasa 19',
    startLat: 44.8279,
    startLong: 20.4602,
    endLat: 44.8279,
    endLong: 20.4602,
  },
  {
    name: 'Južni bulevar 174',
    startLat: 44.7923,
    startLong: 20.4891,
    endLat: 44.7923,
    endLong: 20.4891,
  },
  {
    name: 'Pregrevica Sky & Sun Residences',
    startLat: 44.8518,
    startLong: 20.4027,
    endLat: 44.8518,
    endLong: 20.4027,
  },
  {
    name: 'Pregrevica Sky & Sun Residences',
    startLat: 44.8515,
    startLong: 20.4025,
    endLat: 44.8515,
    endLong: 20.4025,
  },
  { name: 'Pirotska 8', startLat: 44.7887, startLong: 20.4766, endLat: 44.7887, endLong: 20.4766 },
  {
    name: 'Luke Vukalovića 4',
    startLat: 44.8028,
    startLong: 20.4831,
    endLat: 44.8028,
    endLong: 20.4831,
  },
  { name: 'Rudnička 10', startLat: 44.7924, startLong: 20.4682, endLat: 44.7924, endLong: 20.4682 },
  {
    name: 'Gospodara Vučića 10',
    startLat: 44.7905,
    startLong: 20.4731,
    endLat: 44.7905,
    endLong: 20.4731,
  },
  { name: 'Zen Hill', startLat: 44.849, startLong: 20.4008, endLat: 44.8489, endLong: 20.4007 },
  {
    name: 'Karpoševa 27',
    startLat: 44.7844,
    startLong: 20.4158,
    endLat: 44.7844,
    endLong: 20.4158,
  },
  {
    name: 'Ugrinovačka 158',
    startLat: 44.8504,
    startLong: 20.3837,
    endLat: 44.8504,
    endLong: 20.3837,
  },
  { name: 'Dravska 14', startLat: 44.7977, startLong: 20.4896, endLat: 44.7977, endLong: 20.4896 },
  { name: 'Banijska 86', startLat: 44.8492, startLong: 20.3852, endLat: 44.8492, endLong: 20.3852 },
  {
    name: 'BIG Residences',
    startLat: 44.8166,
    startLong: 20.5127,
    endLat: 44.8166,
    endLong: 20.5127,
  },
  {
    name: 'BIG Residences',
    startLat: 44.8166,
    startLong: 20.5119,
    endLat: 44.8166,
    endLong: 20.5119,
  },
  {
    name: 'BIG Residences',
    startLat: 44.8166,
    startLong: 20.5111,
    endLat: 44.8166,
    endLong: 20.5111,
  },
  {
    name: 'BIG Residences',
    startLat: 44.817,
    startLong: 20.5132,
    endLat: 44.817,
    endLong: 20.5132,
  },
  {
    name: 'Despota Đurđa 9',
    startLat: 44.8261,
    startLong: 20.4594,
    endLat: 44.8261,
    endLong: 20.4594,
  },
  {
    name: 'Bore Markovića 14-22',
    startLat: 44.781,
    startLong: 20.4181,
    endLat: 44.781,
    endLong: 20.4181,
  },
  {
    name: 'Bore Markovića 14-22',
    startLat: 44.7811,
    startLong: 20.4184,
    endLat: 44.7811,
    endLong: 20.4184,
  },
  { name: 'Vladetina 4', startLat: 44.8083, startLong: 20.4773, endLat: 44.8083, endLong: 20.4773 },
  { name: 'BW Riviera', startLat: 44.8071, startLong: 20.4488, endLat: 44.8071, endLong: 20.4488 },
  {
    name: 'Požeška Residence',
    startLat: 44.7839,
    startLong: 20.4228,
    endLat: 44.7839,
    endLong: 20.4228,
  },
  {
    name: 'Požeška Residence',
    startLat: 44.7839,
    startLong: 20.4231,
    endLat: 44.7839,
    endLong: 20.4231,
  },
  {
    name: 'Mlatišumina 23',
    startLat: 44.8024,
    startLong: 20.478,
    endLat: 44.8024,
    endLong: 20.478,
  },
  { name: 'Sun City', startLat: 44.8041, startLong: 20.387, endLat: 44.8041, endLong: 20.387 },
  {
    name: 'Radoja Dakića 31',
    startLat: 44.8369,
    startLong: 20.4066,
    endLat: 44.8369,
    endLong: 20.4066,
  },
  {
    name: 'Miklošićeva 14',
    startLat: 44.7902,
    startLong: 20.4813,
    endLat: 44.7902,
    endLong: 20.4812,
  },
  {
    name: 'Kumodraška 3',
    startLat: 44.7848,
    startLong: 20.4751,
    endLat: 44.7848,
    endLong: 20.4751,
  },
  {
    name: 'Svetog Nikole 38',
    startLat: 44.8067,
    startLong: 20.4871,
    endLat: 44.8067,
    endLong: 20.4871,
  },
  {
    name: 'Kruševačka 28',
    startLat: 44.7885,
    startLong: 20.4838,
    endLat: 44.7885,
    endLong: 20.4838,
  },
  { name: 'Rajačka 4', startLat: 44.7659, startLong: 20.501, endLat: 44.7659, endLong: 20.501 },
  {
    name: 'Preševska 24',
    startLat: 44.7999,
    startLong: 20.4934,
    endLat: 44.7999,
    endLong: 20.4934,
  },
  {
    name: 'Cara Dušana 224',
    startLat: 44.8568,
    startLong: 20.384,
    endLat: 44.8568,
    endLong: 20.384,
  },
  {
    name: 'Oblakovska 43',
    startLat: 44.7924,
    startLong: 20.4636,
    endLat: 44.7924,
    endLong: 20.4636,
  },
  {
    name: 'Oblakovska 43',
    startLat: 44.7924,
    startLong: 20.4635,
    endLat: 44.7924,
    endLong: 20.4635,
  },
  { name: 'Karpoševa 8', startLat: 44.7855, startLong: 20.4166, endLat: 44.7855, endLong: 20.4166 },
  {
    name: 'Sime Šolaje 36',
    startLat: 44.8159,
    startLong: 20.5001,
    endLat: 44.8159,
    endLong: 20.5001,
  },
  {
    name: 'Podmorničara Đorđa Mitrovića 12a',
    startLat: 44.7935,
    startLong: 20.5424,
    endLat: 44.7935,
    endLong: 20.5424,
  },
  {
    name: 'Podmorničara Đorđa Mitrovića 12a',
    startLat: 44.7932,
    startLong: 20.5425,
    endLat: 44.7932,
    endLong: 20.5425,
  },
  {
    name: 'Gornjogradska 53',
    startLat: 44.8462,
    startLong: 20.3956,
    endLat: 44.8462,
    endLong: 20.3956,
  },
  {
    name: 'Gospodara Vučića 123',
    startLat: 44.7922,
    startLong: 20.4855,
    endLat: 44.7922,
    endLong: 20.4855,
  },
  {
    name: 'Dunavska trilogija',
    startLat: 44.8534,
    startLong: 20.3982,
    endLat: 44.8534,
    endLong: 20.3982,
  },
  {
    name: 'Dunavska trilogija',
    startLat: 44.8538,
    startLong: 20.3984,
    endLat: 44.8538,
    endLong: 20.3984,
  },
  {
    name: 'Kordunaška 15',
    startLat: 44.8435,
    startLong: 20.3975,
    endLat: 44.8435,
    endLong: 20.3975,
  },
  { name: 'Skadarska 3', startLat: 44.8364, startLong: 20.4027, endLat: 44.8364, endLong: 20.4027 },
  {
    name: 'Davida Pajića 8',
    startLat: 44.7914,
    startLong: 20.4839,
    endLat: 44.7914,
    endLong: 20.4839,
  },
  {
    name: 'Vojvode Skopljanca 15',
    startLat: 44.7833,
    startLong: 20.4797,
    endLat: 44.7833,
    endLong: 20.4797,
  },
  { name: 'Cerska 66', startLat: 44.795, startLong: 20.4833, endLat: 44.795, endLong: 20.4833 },
  {
    name: 'Milana Milićevića 2',
    startLat: 44.8023,
    startLong: 20.4893,
    endLat: 44.8023,
    endLong: 20.4893,
  },
  { name: 'Lux 51', startLat: 44.8232, startLong: 20.387, endLat: 44.8232, endLong: 20.387 },
  { name: 'Lux 51', startLat: 44.823, startLong: 20.388, endLat: 44.823, endLong: 20.388 },
  { name: 'Lux 51', startLat: 44.8226, startLong: 20.3878, endLat: 44.8226, endLong: 20.3878 },
  { name: 'Lux 51', startLat: 44.8226, startLong: 20.3875, endLat: 44.8226, endLong: 20.3875 },
  { name: 'Solunska 22', startLat: 44.8267, startLong: 20.4593, endLat: 44.8267, endLong: 20.4593 },
  { name: 'Mišarska 3', startLat: 44.8069, startLong: 20.4659, endLat: 44.8069, endLong: 20.4659 },
  { name: 'Mišarska 3', startLat: 44.8068, startLong: 20.466, endLat: 44.8068, endLong: 20.466 },
  { name: 'TR Plaza', startLat: 44.7923, startLong: 20.5035, endLat: 44.7923, endLong: 20.5035 },
  {
    name: 'Oaza Mirijevo',
    startLat: 44.7902,
    startLong: 20.5449,
    endLat: 44.7902,
    endLong: 20.5449,
  },
  {
    name: 'Oaza Mirijevo',
    startLat: 44.7905,
    startLong: 20.5448,
    endLat: 44.7905,
    endLong: 20.5448,
  },
  { name: 'Aster Park', startLat: 44.7866, startLong: 20.5151, endLat: 44.7866, endLong: 20.5151 },
  {
    name: 'Vojvode Šupljikca 11',
    startLat: 44.8015,
    startLong: 20.4829,
    endLat: 44.8015,
    endLong: 20.4829,
  },
  {
    name: 'Vojvode Šupljikca 13',
    startLat: 44.8015,
    startLong: 20.4832,
    endLat: 44.8015,
    endLong: 20.4832,
  },
  {
    name: 'Milete Jakšića 8',
    startLat: 44.8052,
    startLong: 20.4964,
    endLat: 44.8052,
    endLong: 20.4964,
  },
  {
    name: 'Milete Jakšića 8',
    startLat: 44.8051,
    startLong: 20.4962,
    endLat: 44.8051,
    endLong: 20.4962,
  },
  { name: 'BW', startLat: 44.8098, startLong: 20.4489, endLat: 44.8098, endLong: 20.4489 },
  {
    name: 'Kolašinska 31',
    startLat: 44.7902,
    startLong: 20.4881,
    endLat: 44.7902,
    endLong: 20.4881,
  },
  {
    name: 'Kolašinska 31',
    startLat: 44.7901,
    startLong: 20.4883,
    endLat: 44.7901,
    endLong: 20.4883,
  },
  {
    name: 'Dr Velizara Kosanovića 54',
    startLat: 44.7887,
    startLong: 20.5058,
    endLat: 44.7887,
    endLong: 20.5058,
  },
  { name: 'Ilindenska 1', startLat: 44.787, startLong: 20.5187, endLat: 44.787, endLong: 20.5187 },
  {
    name: 'Ilindenska 1',
    startLat: 44.7873,
    startLong: 20.5188,
    endLat: 44.7873,
    endLong: 20.5188,
  },
  {
    name: 'Cara Dušana 155',
    startLat: 44.857,
    startLong: 20.3821,
    endLat: 44.857,
    endLong: 20.3821,
  },
  { name: 'Geteova 17', startLat: 44.8415, startLong: 20.3767, endLat: 44.8415, endLong: 20.3767 },
  { name: 'Dorćol House', startLat: 44.8225, startLong: 20.461, endLat: 44.8225, endLong: 20.461 },
  {
    name: 'Park Forest Residence',
    startLat: 44.8064,
    startLong: 20.5028,
    endLat: 44.8064,
    endLong: 20.5028,
  },
  {
    name: 'Park Forest Residence',
    startLat: 44.8065,
    startLong: 20.5028,
    endLat: 44.8065,
    endLong: 20.5028,
  },
  {
    name: 'Park Forest Residence',
    startLat: 44.8065,
    startLong: 20.5031,
    endLat: 44.8065,
    endLong: 20.5031,
  },
  {
    name: 'Koste Stojanovića 6',
    startLat: 44.8139,
    startLong: 20.4702,
    endLat: 44.8139,
    endLong: 20.4702,
  },
  {
    name: 'Rade Končara 83',
    startLat: 44.8486,
    startLong: 20.386,
    endLat: 44.8486,
    endLong: 20.386,
  },
  {
    name: 'Crnogorska 18',
    startLat: 44.8433,
    startLong: 20.3952,
    endLat: 44.8433,
    endLong: 20.3952,
  },
  {
    name: 'Vojvode Hrvoja 12',
    startLat: 44.7916,
    startLong: 20.4747,
    endLat: 44.7916,
    endLong: 20.4747,
  },
  {
    name: 'Skender-Begova 12-14',
    startLat: 44.8234,
    startLong: 20.4614,
    endLat: 44.8234,
    endLong: 20.4614,
  },
  { name: 'Rtanjska 31', startLat: 44.8046, startLong: 20.4871, endLat: 44.8046, endLong: 20.4871 },
  { name: 'Vinodolska 12', startLat: 44.7812, startLong: 20.411, endLat: 44.7812, endLong: 20.411 },
  { name: 'Pazinska 21', startLat: 44.762, startLong: 20.4817, endLat: 44.762, endLong: 20.4817 },
  { name: 'Pazinska 23', startLat: 44.7621, startLong: 20.4816, endLat: 44.7621, endLong: 20.4816 },
  {
    name: 'Admirala Vukovića 17a',
    startLat: 44.7855,
    startLong: 20.4705,
    endLat: 44.7855,
    endLong: 20.4705,
  },
  { name: 'Prizrenska 11', startLat: 44.8509, startLong: 20.384, endLat: 44.8509, endLong: 20.384 },
  {
    name: 'Pašmanska 13',
    startLat: 44.7901,
    startLong: 20.4907,
    endLat: 44.7901,
    endLong: 20.4907,
  },
  {
    name: 'Borisavljevićeva 34',
    startLat: 44.7842,
    startLong: 20.4724,
    endLat: 44.7842,
    endLong: 20.4724,
  },
  {
    name: 'Pregrevica 140',
    startLat: 44.8572,
    startLong: 20.3863,
    endLat: 44.8572,
    endLong: 20.3863,
  },
  {
    name: 'Pregrevica 140',
    startLat: 44.8576,
    startLong: 20.3867,
    endLat: 44.8576,
    endLong: 20.3867,
  },
  {
    name: 'Kumodraška 41',
    startLat: 44.7827,
    startLong: 20.4777,
    endLat: 44.7827,
    endLong: 20.4777,
  },
  {
    name: 'Vojvode Hrvoja 37',
    startLat: 44.7914,
    startLong: 20.4738,
    endLat: 44.7914,
    endLong: 20.4738,
  },
  {
    name: 'Vojvode Hrvoja 37',
    startLat: 44.7914,
    startLong: 20.4736,
    endLat: 44.7914,
    endLong: 20.4736,
  },
  {
    name: 'Petrovaradinska 11',
    startLat: 44.7899,
    startLong: 20.487,
    endLat: 44.7899,
    endLong: 20.487,
  },
  {
    name: 'Lazara Kujundžića 5-7',
    startLat: 44.7855,
    startLong: 20.416,
    endLat: 44.7855,
    endLong: 20.416,
  },
  {
    name: 'Cara Dušana 100',
    startLat: 44.8512,
    startLong: 20.3978,
    endLat: 44.8512,
    endLong: 20.3978,
  },
  {
    name: 'Borisavljevićeva 42',
    startLat: 44.7841,
    startLong: 20.473,
    endLat: 44.7841,
    endLong: 20.473,
  },
  {
    name: 'Gornjogradska 54V',
    startLat: 44.8459,
    startLong: 20.3972,
    endLat: 44.8459,
    endLong: 20.3972,
  },
  {
    name: 'Živka Davidovića 95',
    startLat: 44.7863,
    startLong: 20.5151,
    endLat: 44.7863,
    endLong: 20.5151,
  },
  {
    name: 'Miodraga Petrovića Čkalje 11',
    startLat: 44.7918,
    startLong: 20.5038,
    endLat: 44.7918,
    endLong: 20.5038,
  },
  {
    name: 'Dubljanska 46',
    startLat: 44.7956,
    startLong: 20.4729,
    endLat: 44.7956,
    endLong: 20.4729,
  },
  {
    name: 'Jasenička 9-11',
    startLat: 44.7879,
    startLong: 20.4984,
    endLat: 44.7879,
    endLong: 20.4984,
  },
  { name: 'BW', startLat: 44.8049, startLong: 20.4493, endLat: 44.8049, endLong: 20.4493 },
  { name: 'Pionir Palas', startLat: 44.8159, startLong: 20.487, endLat: 44.8159, endLong: 20.487 },
  {
    name: 'Dr Velizara Kosanovića 46',
    startLat: 44.7894,
    startLong: 20.5059,
    endLat: 44.7894,
    endLong: 20.5059,
  },
  {
    name: 'Dr Velizara Kosanovića 48',
    startLat: 44.7892,
    startLong: 20.506,
    endLat: 44.7892,
    endLong: 20.506,
  },
  {
    name: 'Vladimira Gortana 2',
    startLat: 44.7869,
    startLong: 20.5149,
    endLat: 44.7869,
    endLong: 20.5149,
  },
  {
    name: 'Skopljanska 12',
    startLat: 44.8558,
    startLong: 20.3809,
    endLat: 44.8558,
    endLong: 20.3809,
  },
  {
    name: 'Skopljanska 12',
    startLat: 44.8558,
    startLong: 20.3811,
    endLat: 44.8558,
    endLong: 20.3811,
  },
  { name: 'BW', startLat: 44.8039, startLong: 20.4468, endLat: 44.8039, endLong: 20.4468 },
  {
    name: 'East Point Vračar',
    startLat: 44.7923,
    startLong: 20.4936,
    endLat: 44.7923,
    endLong: 20.4936,
  },
  {
    name: 'Šumatovačka 105',
    startLat: 44.7947,
    startLong: 20.482,
    endLat: 44.7947,
    endLong: 20.482,
  },
  {
    name: 'Sazonova 64-66',
    startLat: 44.7951,
    startLong: 20.4843,
    endLat: 44.7951,
    endLong: 20.4843,
  },
  { name: 'Sazonova 64-66', startLat: 44.7951, startLong: 20.4844, endLat: 44.7951, endLong: 20.4844 },
  {
    name: 'Cara Uroša 41',
    startLat: 44.8248,
    startLong: 20.4592,
    endLat: 44.8248,
    endLong: 20.4592,
  },
  {
    name: 'Cara Uroša 73',
    startLat: 44.8281,
    startLong: 20.4604,
    endLat: 44.8281,
    endLong: 20.4604,
  },
  { name: 'Villa Banjica', startLat: 44.752, startLong: 20.4755, endLat: 44.752, endLong: 20.4755 },
  { name: 'Square 2', startLat: 44.7587, startLong: 20.4861, endLat: 44.7587, endLong: 20.4861 },
  { name: 'Square 2', startLat: 44.7586, startLong: 20.4862, endLat: 44.7586, endLong: 20.4862 },
  { name: 'Square 2', startLat: 44.7588, startLong: 20.4861, endLat: 44.7588, endLong: 20.4861 },
  { name: 'Kovačeva 38', startLat: 44.7885, startLong: 20.5048, endLat: 44.7885, endLong: 20.5048 },
  {
    name: 'Paje Jovanovića 21',
    startLat: 44.7898,
    startLong: 20.5069,
    endLat: 44.7898,
    endLong: 20.5069,
  },
  {
    name: 'Dr Velizara Kosanovića 12',
    startLat: 44.7912,
    startLong: 20.5074,
    endLat: 44.7912,
    endLong: 20.5074,
  },
  {
    name: 'Miloja Đaka 12a',
    startLat: 44.7681,
    startLong: 20.4566,
    endLat: 44.7681,
    endLong: 20.4566,
  },
  {
    name: 'BW Terra i Aria',
    startLat: 44.8088,
    startLong: 20.4508,
    endLat: 44.8088,
    endLong: 20.4508,
  },
  {
    name: 'BW Terra i Aria',
    startLat: 44.8089,
    startLong: 20.4513,
    endLat: 44.8089,
    endLong: 20.4513,
  },
  {
    name: 'BW Terra i Aria',
    startLat: 44.8086,
    startLong: 20.4504,
    endLat: 44.8086,
    endLong: 20.4504,
  },
  { name: 'BW Simfonija', startLat: 44.81, startLong: 20.4508, endLat: 44.81, endLong: 20.4508 },
  { name: 'BW Simfonija', startLat: 44.81, startLong: 20.4512, endLat: 44.81, endLong: 20.4512 },
  {
    name: 'BW Simfonija',
    startLat: 44.8099,
    startLong: 20.4508,
    endLat: 44.8099,
    endLong: 20.4508,
  },
  {
    name: 'Rade Končara 25',
    startLat: 44.7898,
    startLong: 20.4775,
    endLat: 44.7898,
    endLong: 20.4775,
  },
  {
    name: 'Rade Končara 25',
    startLat: 44.7899,
    startLong: 20.4775,
    endLat: 44.7899,
    endLong: 20.4775,
  },
  {
    name: 'Vrtovi Ceraka',
    startLat: 44.7463,
    startLong: 20.4291,
    endLat: 44.7463,
    endLong: 20.4291,
  },
  {
    name: 'Vrtovi Ceraka',
    startLat: 44.7465,
    startLong: 20.4294,
    endLat: 44.7465,
    endLong: 20.4294,
  },
  {
    name: 'Vrtovi Ceraka',
    startLat: 44.746,
    startLong: 20.4301,
    endLat: 44.746,
    endLong: 20.4301,
  },
  {
    name: 'Vrtovi Ceraka',
    startLat: 44.7461,
    startLong: 20.4295,
    endLat: 44.7461,
    endLong: 20.4295,
  },
  {
    name: 'Vrtovi Ceraka',
    startLat: 44.7463,
    startLong: 20.4297,
    endLat: 44.7463,
    endLong: 20.4297,
  },
  { name: 'Vrtovi Ceraka', startLat: 44.7459, startLong: 20.4302, endLat: 44.7459, endLong: 20.4302 },
  { name: 'Vrtovi Ceraka', startLat: 44.7459, startLong: 20.4304, endLat: 44.7459, endLong: 20.4304 },
  { name: 'BW Aurora', startLat: 44.8061, startLong: 20.4509, endLat: 44.8061, endLong: 20.4509 },
  {
    name: 'BW Vista i Parkview',
    startLat: 44.8074,
    startLong: 20.4504,
    endLat: 44.8074,
    endLong: 20.4504,
  },
  {
    name: 'BW Vista i Parkview',
    startLat: 44.8073,
    startLong: 20.4511,
    endLat: 44.8073,
    endLong: 20.4511,
  },
  {
    name: 'BW Vista i Parkview',
    startLat: 44.8074,
    startLong: 20.4507,
    endLat: 44.8074,
    endLong: 20.4507,
  },
  {
    name: 'BW Vista i Parkview',
    startLat: 44.8069,
    startLong: 20.4506,
    endLat: 44.8069,
    endLong: 20.4506,
  },
  {
    name: 'BW Vista i Parkview',
    startLat: 44.8078,
    startLong: 20.4511,
    endLat: 44.8078,
    endLong: 20.4511,
  },
  {
    name: 'BW Vista i Parkview',
    startLat: 44.8075,
    startLong: 20.4504,
    endLat: 44.8075,
    endLong: 20.4504,
  },
  {
    name: 'BW Metropolitan',
    startLat: 44.8102,
    startLong: 20.452,
    endLat: 44.8102,
    endLong: 20.452,
  },
  {
    name: 'BW Metropolitan',
    startLat: 44.8098,
    startLong: 20.4518,
    endLat: 44.8098,
    endLong: 20.4518,
  },
  {
    name: 'BW Magnolija',
    startLat: 44.8111,
    startLong: 20.4508,
    endLat: 44.8111,
    endLong: 20.4508,
  },
  {
    name: 'BW Magnolija',
    startLat: 44.8111,
    startLong: 20.4512,
    endLat: 44.8111,
    endLong: 20.4512,
  },
  {
    name: 'BW Magnolija',
    startLat: 44.8114,
    startLong: 20.4508,
    endLat: 44.8114,
    endLong: 20.4508,
  },
  {
    name: 'Kosare Cvetković 20',
    startLat: 44.8071,
    startLong: 20.4967,
    endLat: 44.8071,
    endLong: 20.4967,
  },
  {
    name: 'Sazonova 22',
    startLat: 44.7966,
    startLong: 20.4792,
    endLat: 44.7966,
    endLong: 20.4792,
  },
  {
    name: 'Sazonova 21',
    startLat: 44.7968,
    startLong: 20.4793,
    endLat: 44.7968,
    endLong: 20.4793,
  },
  {
    name: 'Sazonova 54',
    startLat: 44.7955,
    startLong: 20.4835,
    endLat: 44.7955,
    endLong: 20.4835,
  },
  {
    name: 'Maglajska 22',
    startLat: 44.7853,
    startLong: 20.4609,
    endLat: 44.7853,
    endLong: 20.4609,
  },
  {
    name: 'Banatska 83',
    startLat: 44.8581,
    startLong: 20.3773,
    endLat: 44.8581,
    endLong: 20.3773,
  },
  {
    name: 'Banatska 83',
    startLat: 44.8584,
    startLong: 20.3772,
    endLat: 44.8584,
    endLong: 20.3772,
  },
  { name: 'Banatska 83', startLat: 44.8584, startLong: 20.3773, endLat: 44.8584, endLong: 20.3773 },
  {
    name: 'Donska 2',
    startLat: 44.7918,
    startLong: 20.5047,
    endLat: 44.7918,
    endLong: 20.5047,
  },
  {
    name: 'Stepina Magnolija',
    startLat: 44.7636,
    startLong: 20.4852,
    endLat: 44.7636,
    endLong: 20.4852,
  },
  {
    name: 'Stepina Magnolija',
    startLat: 44.7637,
    startLong: 20.4852,
    endLat: 44.7637,
    endLong: 20.4852,
  },
  {
    name: 'Stepina Magnolija',
    startLat: 44.7638,
    startLong: 20.4852,
    endLat: 44.7638,
    endLong: 20.4852,
  },
  {
    name: 'Dolce Vita',
    startLat: 44.82,
    startLong: 20.3952,
    endLat: 44.82,
    endLong: 20.3952,
  },
  {
    name: 'Subotička 28',
    startLat: 44.7924,
    startLong: 20.5003,
    endLat: 44.7924,
    endLong: 20.5003,
  },
  {
    name: 'Sime Šolaje 23',
    startLat: 44.8161,
    startLong: 20.4981,
    endLat: 44.8161,
    endLong: 20.4981,
  },
  {
    name: 'Vladimira Gortana 18',
    startLat: 44.7863,
    startLong: 20.5157,
    endLat: 44.7863,
    endLong: 20.5157,
  },
  { name: 'Vladimira Gortana 18', startLat: 44.7861, startLong: 20.5159, endLat: 44.7861, endLong: 20.5159 },
  {
    name: 'Ulcinjska 13',
    startLat: 44.7928,
    startLong: 20.4976,
    endLat: 44.7928,
    endLong: 20.4976,
  },
  {
    name: 'Milana Rakića 119',
    startLat: 44.793,
    startLong: 20.5161,
    endLat: 44.793,
    endLong: 20.5161,
  },
  {
    name: 'Dimitrija Tucovića 148',
    startLat: 44.7999,
    startLong: 20.4986,
    endLat: 44.7999,
    endLong: 20.4986,
  },
  {
    name: 'Vojvode Bogdana 23',
    startLat: 44.8034,
    startLong: 20.4848,
    endLat: 44.8034,
    endLong: 20.4848,
  },
  {
    name: 'Braće Ribnikar 18',
    startLat: 44.8073,
    startLong: 20.4842,
    endLat: 44.8073,
    endLong: 20.4842,
  },
  {
    name: 'Katanićeva 10',
    startLat: 44.7996,
    startLong: 20.4705,
    endLat: 44.7996,
    endLong: 20.4705,
  },
  {
    name: 'Cara Nikolaja II 62',
    startLat: 44.7979,
    startLong: 20.481,
    endLat: 44.7979,
    endLong: 20.481,
  },
  {
    name: 'Paštrovićeva 51',
    startLat: 44.7855,
    startLong: 20.4209,
    endLat: 44.7855,
    endLong: 20.4209,
  },
  {
    name: 'Heroja Milana Tepića 10',
    startLat: 44.7776,
    startLong: 20.4599,
    endLat: 44.7776,
    endLong: 20.4599,
  },
  {
    name: 'Dobropoljska 51',
    startLat: 44.793,
    startLong: 20.4611,
    endLat: 44.793,
    endLong: 20.4611,
  },
  {
    name: 'Gospodara Vučića 224',
    startLat: 44.7924,
    startLong: 20.5033,
    endLat: 44.7924,
    endLong: 20.5033,
  },
  {
    name: 'Milorada Bondžulića 4',
    startLat: 44.786,
    startLong: 20.4797,
    endLat: 44.786,
    endLong: 20.4797,
  },
  {
    name: 'Kačerska 9',
    startLat: 44.7739,
    startLong: 20.4784,
    endLat: 44.7739,
    endLong: 20.4784,
  },
  {
    name: 'Ustanička 80b',
    startLat: 44.7848,
    startLong: 20.4838,
    endLat: 44.7848,
    endLong: 20.4838,
  },
  {
    name: 'Strumička 13',
    startLat: 44.7891,
    startLong: 20.4817,
    endLat: 44.7891,
    endLong: 20.4817,
  },
  {
    name: 'Jove Ilića 43',
    startLat: 44.7831,
    startLong: 20.4694,
    endLat: 44.7831,
    endLong: 20.4694,
  },
  {
    name: 'Izvorska 103',
    startLat: 44.7688,
    startLong: 20.422,
    endLat: 44.7688,
    endLong: 20.422,
  },
  {
    name: 'Šumadijska 6',
    startLat: 44.8562,
    startLong: 20.3823,
    endLat: 44.8562,
    endLong: 20.3823,
  },
  {
    name: 'Ševina 16',
    startLat: 44.8411,
    startLong: 20.3778,
    endLat: 44.8411,
    endLong: 20.3778,
  },
  {
    name: 'Ševina 16',
    startLat: 44.8412,
    startLong: 20.3775,
    endLat: 44.8412,
    endLong: 20.3775,
  },
  {
    name: 'Ševina 16',
    startLat: 44.8412,
    startLong: 20.3776,
    endLat: 44.8412,
    endLong: 20.3776,
  },
  {
    name: "King's Circle Residences",
    startLat: 44.8042,
    startLong: 20.4655,
    endLat: 44.8042,
    endLong: 20.4655,
  },
  {
    name: "King's Circle Residences",
    startLat: 44.8039,
    startLong: 20.4654,
    endLat: 44.8039,
    endLong: 20.4654,
  },
  {
    name: "King's Circle Residences",
    startLat: 44.8044,
    startLong: 20.4652,
    endLat: 44.8044,
    endLong: 20.4652,
  },
  {
    name: "King's Circle Residences",
    startLat: 44.804,
    startLong: 20.4654,
    endLat: 44.804,
    endLong: 20.4654,
  },
  {
    name: 'Vase Stajića 2',
    startLat: 44.7853,
    startLong: 20.4208,
    endLat: 44.7853,
    endLong: 20.4208,
  },
  {
    name: 'Palata Vizantija',
    startLat: 44.8163,
    startLong: 20.488,
    endLat: 44.8163,
    endLong: 20.488,
  },
  {
    name: 'Palata Vizantija',
    startLat: 44.8165,
    startLong: 20.4874,
    endLat: 44.8165,
    endLong: 20.4874,
  },
  {
    name: 'Palata Vizantija',
    startLat: 44.8164,
    startLong: 20.4872,
    endLat: 44.8164,
    endLong: 20.4872,
  },
  {
    name: 'Bulevar Jna 33lj,m,n',
    startLat: 44.7477,
    startLong: 20.4777,
    endLat: 44.7477,
    endLong: 20.4777,
  },
  {
    name: 'Bulevar Jna 33lj,m,n',
    startLat: 44.7477,
    startLong: 20.4772,
    endLat: 44.7477,
    endLong: 20.4772,
  },
  {
    name: 'Bulevar Jna 33lj,m,n',
    startLat: 44.7478,
    startLong: 20.4781,
    endLat: 44.7478,
    endLong: 20.4781,
  },
  {
    name: 'Crnogorska 5',
    startLat: 44.8428,
    startLong: 20.3962,
    endLat: 44.8428,
    endLong: 20.3962,
  },
  {
    name: 'Puškinova 11',
    startLat: 44.7856,
    startLong: 20.437,
    endLat: 44.7856,
    endLong: 20.437,
  },
  {
    name: 'Todora Dukina 93',
    startLat: 44.7864,
    startLong: 20.487,
    endLat: 44.7864,
    endLong: 20.487,
  },
  {
    name: 'Moj Aviv',
    startLat: 44.7857,
    startLong: 20.5151,
    endLat: 44.7857,
    endLong: 20.5151,
  },
  {
    name: 'Moj Aviv',
    startLat: 44.7857,
    startLong: 20.5152,
    endLat: 44.7857,
    endLong: 20.5152,
  },
  {
    name: 'Moj Aviv',
    startLat: 44.7855,
    startLong: 20.5143,
    endLat: 44.7855,
    endLong: 20.5143,
  },
  {
    name: 'Dorćol centar',
    startLat: 44.8232,
    startLong: 20.4617,
    endLat: 44.8232,
    endLong: 20.4617,
  },
  {
    name: 'Subotička 19',
    startLat: 44.7929,
    startLong: 20.5002,
    endLat: 44.7929,
    endLong: 20.5002,
  },
  {
    name: 'Subotička 19',
    startLat: 44.7928,
    startLong: 20.5005,
    endLat: 44.7928,
    endLong: 20.5005,
  },
  {
    name: 'Subotička 19',
    startLat: 44.7929,
    startLong: 20.5003,
    endLat: 44.7929,
    endLong: 20.5003,
  },
  { name: 'Subotička 24', startLat: 44.7926, startLong: 20.5, endLat: 44.7926, endLong: 20.5 },
  { name: 'Subotička 20', startLat: 44.7926, startLong: 20.5, endLat: 44.7926, endLong: 20.5 },
  {
    name: 'Ravanička 46',
    startLat: 44.7928,
    startLong: 20.4997,
    endLat: 44.7928,
    endLong: 20.4997,
  },
  {
    name: 'Ravanička 44',
    startLat: 44.7925,
    startLong: 20.4989,
    endLat: 44.7925,
    endLong: 20.4989,
  },
  { name: 'Krfska 20', startLat: 44.8032, startLong: 20.4919, endLat: 44.8032, endLong: 20.4919 },
  {
    name: 'Nikole Čupića 2',
    startLat: 44.7913,
    startLong: 20.4997,
    endLat: 44.7913,
    endLong: 20.4997,
  },
  {
    name: 'Slobodanke Danke Savić 7',
    startLat: 44.7931,
    startLong: 20.5025,
    endLat: 44.7931,
    endLong: 20.5025,
  },
  {
    name: 'Slobodanke Danke Savić 7',
    startLat: 44.7931,
    startLong: 20.5026,
    endLat: 44.7931,
    endLong: 20.5026,
  },
  {
    name: 'Dr Velizara Kosanovića 56',
    startLat: 44.7879,
    startLong: 20.5057,
    endLat: 44.7879,
    endLong: 20.5057,
  },
  {
    name: 'Dr Velizara Kosanovića 56',
    startLat: 44.7879,
    startLong: 20.5056,
    endLat: 44.7879,
    endLong: 20.5056,
  },
  {
    name: 'Dr Velizara Kosanovića 56',
    startLat: 44.788,
    startLong: 20.5056,
    endLat: 44.788,
    endLong: 20.5056,
  },
  {
    name: 'Braće Miladinov 2',
    startLat: 44.7918,
    startLong: 20.5017,
    endLat: 44.7918,
    endLong: 20.5017,
  },
  {
    name: 'Braće Miladinov 2',
    startLat: 44.7918,
    startLong: 20.5016,
    endLat: 44.7918,
    endLong: 20.5016,
  },
  {
    name: 'Križanićeva 10',
    startLat: 44.7912,
    startLong: 20.5004,
    endLat: 44.7912,
    endLong: 20.5004,
  },
  {
    name: 'Križanićeva 10',
    startLat: 44.7912,
    startLong: 20.5005,
    endLat: 44.7912,
    endLong: 20.5005,
  },
  {
    name: 'Belville',
    startLat: 44.8054,
    startLong: 20.409,
    endLat: 44.8054,
    endLong: 20.409,
  },
  {
    name: 'Belville',
    startLat: 44.8052,
    startLong: 20.4092,
    endLat: 44.8052,
    endLong: 20.4092,
  },
  {
    name: 'Belville',
    startLat: 44.8058,
    startLong: 20.4084,
    endLat: 44.8058,
    endLong: 20.4084,
  },
  {
    name: 'Belville',
    startLat: 44.8075,
    startLong: 20.4047,
    endLat: 44.8075,
    endLong: 20.4047,
  },
  {
    name: 'Belville',
    startLat: 44.8056,
    startLong: 20.4097,
    endLat: 44.8056,
    endLong: 20.4097,
  },
  {
    name: 'Belville',
    startLat: 44.8057,
    startLong: 20.4077,
    endLat: 44.8057,
    endLong: 20.4077,
  },
  {
    name: 'Belville',
    startLat: 44.8054,
    startLong: 20.4084,
    endLat: 44.8054,
    endLong: 20.4084,
  },
  {
    name: 'Belville',
    startLat: 44.8055,
    startLong: 20.407,
    endLat: 44.8055,
    endLong: 20.407,
  },
  {
    name: 'Belville',
    startLat: 44.8065,
    startLong: 20.4071,
    endLat: 44.8065,
    endLong: 20.4071,
  },
  {
    name: 'Belville',
    startLat: 44.8046,
    startLong: 20.4115,
    endLat: 44.8046,
    endLong: 20.4115,
  },
  {
    name: 'Belville',
    startLat: 44.8047,
    startLong: 20.4111,
    endLat: 44.8047,
    endLong: 20.4111,
  },
  {
    name: 'Belville',
    startLat: 44.8054,
    startLong: 20.4104,
    endLat: 44.8054,
    endLong: 20.4104,
  },
  {
    name: 'Belville',
    startLat: 44.8053,
    startLong: 20.4078,
    endLat: 44.8053,
    endLong: 20.4078,
  },
  {
    name: 'Belville',
    startLat: 44.8052,
    startLong: 20.4095,
    endLat: 44.8052,
    endLong: 20.4095,
  },
  {
    name: 'Belville',
    startLat: 44.8051,
    startLong: 20.4073,
    endLat: 44.8051,
    endLong: 20.4073,
  },
  {
    name: 'Belville',
    startLat: 44.8051,
    startLong: 20.41,
    endLat: 44.8051,
    endLong: 20.41,
  },
  {
    name: 'Belville',
    startLat: 44.806,
    startLong: 20.4066,
    endLat: 44.806,
    endLong: 20.4066,
  },
  {
    name: 'Belville',
    startLat: 44.8067,
    startLong: 20.4067,
    endLat: 44.8067,
    endLong: 20.4067,
  },
  {
    name: 'Belville',
    startLat: 44.8072,
    startLong: 20.4055,
    endLat: 44.8072,
    endLong: 20.4055,
  },
  {
    name: 'Belville',
    startLat: 44.807,
    startLong: 20.406,
    endLat: 44.807,
    endLong: 20.406,
  },
  {
    name: 'Belville',
    startLat: 44.8056,
    startLong: 20.409,
    endLat: 44.8056,
    endLong: 20.409,
  },
  { name: 'Belville', startLat: 44.8062, startLong: 20.4065, endLat: 44.8062, endLong: 20.4065 },
  { name: 'Belville', startLat: 44.8062, startLong: 20.4075, endLat: 44.8062, endLong: 20.4075 },
  { name: 'A Blok', startLat: 44.8062, startLong: 20.4009, endLat: 44.8062, endLong: 20.4009 },
  { name: 'A Blok', startLat: 44.8062, startLong: 20.3999, endLat: 44.8062, endLong: 20.3999 },
  { name: 'A Blok', startLat: 44.8062, startLong: 20.3996, endLat: 44.8062, endLong: 20.3996 },
  { name: 'A Blok', startLat: 44.8062, startLong: 20.4005, endLat: 44.8062, endLong: 20.4005 },
  { name: 'A Blok', startLat: 44.8052, startLong: 20.4014, endLat: 44.8052, endLong: 20.4014 },
  { name: 'A Blok', startLat: 44.8052, startLong: 20.4021, endLat: 44.8052, endLong: 20.4021 },
  { name: 'A Blok', startLat: 44.8051, startLong: 20.4013, endLat: 44.8051, endLong: 20.4013 },
  { name: 'A Blok', startLat: 44.8057, startLong: 20.3996, endLat: 44.8057, endLong: 20.3996 },
  { name: 'A Blok', startLat: 44.8052, startLong: 20.4004, endLat: 44.8052, endLong: 20.4004 },
  { name: 'A Blok', startLat: 44.8057, startLong: 20.4002, endLat: 44.8057, endLong: 20.4002 },
  { name: 'A Blok', startLat: 44.8053, startLong: 20.4004, endLat: 44.8053, endLong: 20.4004 },
  { name: 'A Blok', startLat: 44.8062, startLong: 20.402, endLat: 44.8062, endLong: 20.402 },
  {
    name: 'Rada Neimara 41',
    startLat: 44.7879,
    startLong: 20.4934,
    endLat: 44.7879,
    endLong: 20.4934,
  },
  { name: 'Rada Neimara 41', startLat: 44.7879, startLong: 20.4935, endLat: 44.7879, endLong: 20.4935 },
  { name: 'Krfska 26', startLat: 44.8039, startLong: 20.4924, endLat: 44.8039, endLong: 20.4924 },
  {
    name: 'Leskovačka 2',
    startLat: 44.7862,
    startLong: 20.4687,
    endLat: 44.7862,
    endLong: 20.4687,
  },
  {
    name: 'Lion Residence',
    startLat: 44.7976,
    startLong: 20.5022,
    endLat: 44.7976,
    endLong: 20.5022,
  },
  {
    name: 'Lion Residence',
    startLat: 44.7977,
    startLong: 20.5025,
    endLat: 44.7977,
    endLong: 20.5025,
  },
  {
    name: 'Lion Residence',
    startLat: 44.7978,
    startLong: 20.5021,
    endLat: 44.7978,
    endLong: 20.5021,
  },
  {
    name: 'Lion Residence',
    startLat: 44.7977,
    startLong: 20.5023,
    endLat: 44.7977,
    endLong: 20.5023,
  },
  { name: 'MM41 Square', startLat: 44.7806, startLong: 20.4693, endLat: 44.7806, endLong: 20.4693 },
  {
    name: 'Neznanog junaka 15a',
    startLat: 44.775,
    startLong: 20.4611,
    endLat: 44.775,
    endLong: 20.4611,
  },
  {
    name: "King's Fountain Dedinje",
    startLat: 44.7622,
    startLong: 20.4577,
    endLat: 44.7622,
    endLong: 20.4577,
  },
  {
    name: "King's Fountain Dedinje",
    startLat: 44.7622,
    startLong: 20.4574,
    endLat: 44.7622,
    endLong: 20.4574,
  },
  { name: 'Bel Mondo', startLat: 44.8147, startLong: 20.404, endLat: 44.8147, endLong: 20.404 },
  { name: 'Bel Mondo', startLat: 44.8142, startLong: 20.4036, endLat: 44.8142, endLong: 20.4036 },
  { name: 'Bel Mondo', startLat: 44.8148, startLong: 20.4037, endLat: 44.8148, endLong: 20.4037 },
  {
    name: 'Dalmatinska 53',
    startLat: 44.8116,
    startLong: 20.4778,
    endLat: 44.8116,
    endLong: 20.4778,
  },
  {
    name: 'Rade Končara 21',
    startLat: 44.7899,
    startLong: 20.4777,
    endLat: 44.7899,
    endLong: 20.4777,
  },
  {
    name: 'Dobropoljska 66',
    startLat: 44.7929,
    startLong: 20.4616,
    endLat: 44.7929,
    endLong: 20.4616,
  },
  {
    name: 'Victory Gardens',
    startLat: 44.7722,
    startLong: 20.4086,
    endLat: 44.7722,
    endLong: 20.4086,
  },
  {
    name: 'Victory Gardens',
    startLat: 44.7711,
    startLong: 20.4098,
    endLat: 44.7711,
    endLong: 20.4098,
  },
  {
    name: 'Victory Gardens',
    startLat: 44.7707,
    startLong: 20.4101,
    endLat: 44.7707,
    endLong: 20.4101,
  },
  {
    name: 'Surdulička 4',
    startLat: 44.7902,
    startLong: 20.4653,
    endLat: 44.7902,
    endLong: 20.4653,
  },
  {
    name: 'Buena Vista Residences',
    startLat: 44.7453,
    startLong: 20.4291,
    endLat: 44.7453,
    endLong: 20.4291,
  },
  {
    name: 'Buena Vista Residences',
    startLat: 44.7451,
    startLong: 20.4288,
    endLat: 44.7451,
    endLong: 20.4288,
  },
  {
    name: 'Buena Vista Residences',
    startLat: 44.7456,
    startLong: 20.4294,
    endLat: 44.7456,
    endLong: 20.4294,
  },
  {
    name: 'Residence 7+',
    startLat: 44.8359,
    startLong: 20.3908,
    endLat: 44.8359,
    endLong: 20.3908,
  },
  { name: 'Residence 7+', startLat: 44.836, startLong: 20.3904, endLat: 44.836, endLong: 20.3904 },
  { name: 'Residence 7+', startLat: 44.8362, startLong: 20.3903, endLat: 44.8362, endLong: 20.3903 },
  {
    name: 'Voždov Kvart',
    startLat: 44.7642,
    startLong: 20.4881,
    endLat: 44.7642,
    endLong: 20.4881,
  },
  { name: 'Voždov Kvart', startLat: 44.764, startLong: 20.4884, endLat: 44.764, endLong: 20.4884 },
  {
    name: 'Voždov Kvart',
    startLat: 44.7637,
    startLong: 20.4871,
    endLat: 44.7637,
    endLong: 20.4871,
  },
  {
    name: 'Braće Miladinov 15',
    startLat: 44.7913,
    startLong: 20.5026,
    endLat: 44.7913,
    endLong: 20.5026,
  },
  {
    name: 'Vukice Mitrović 74',
    startLat: 44.7977,
    startLong: 20.4836,
    endLat: 44.7977,
    endLong: 20.4836,
  },
  {
    name: 'Primorska 16',
    startLat: 44.8111,
    startLong: 20.4817,
    endLat: 44.8111,
    endLong: 20.4817,
  },
  {
    name: 'Onisima Popovića 12',
    startLat: 44.7896,
    startLong: 20.5114,
    endLat: 44.7896,
    endLong: 20.5114,
  },
  {
    name: 'Mihaila Đurića 15',
    startLat: 44.7877,
    startLong: 20.4919,
    endLat: 44.7877,
    endLong: 20.4919,
  },
  {
    name: 'Ruđera Boškovića 14',
    startLat: 44.7895,
    startLong: 20.5141,
    endLat: 44.7895,
    endLong: 20.5141,
  },
  {
    name: 'Square Garden',
    startLat: 45.2722,
    startLong: 19.8053,
    endLat: 45.2722,
    endLong: 19.8053,
  },
  {
    name: 'Square Garden',
    startLat: 45.2718,
    startLong: 19.8046,
    endLat: 45.2718,
    endLong: 19.8046,
  },
  { name: 'Blok D', startLat: 45.2471, startLong: 19.8186, endLat: 45.2471, endLong: 19.8186 },
  { name: 'Blok D', startLat: 45.2469, startLong: 19.8189, endLat: 45.2469, endLong: 19.8189 },
  { name: 'Blok D', startLat: 45.2467, startLong: 19.8183, endLat: 45.2467, endLong: 19.8183 },
  { name: 'Tesla Palace', startLat: 44.023, startLong: 20.9194, endLat: 44.023, endLong: 20.9194 },
  {
    name: 'Tesla Palace',
    startLat: 44.0232,
    startLong: 20.9197,
    endLat: 44.0232,
    endLong: 20.9197,
  },
  {
    name: 'Tesla Palace',
    startLat: 44.0233,
    startLong: 20.9192,
    endLat: 44.0233,
    endLong: 20.9192,
  },
  {
    name: 'Tesla Palace',
    startLat: 44.0231,
    startLong: 20.9191,
    endLat: 44.0231,
    endLong: 20.9191,
  },
  {
    name: 'Tesla Palace',
    startLat: 44.0232,
    startLong: 20.9199,
    endLat: 44.0232,
    endLong: 20.9199,
  },
  {
    name: 'Tesla Palace',
    startLat: 44.0232,
    startLong: 20.9195,
    endLat: 44.0232,
    endLong: 20.9195,
  },
  {
    name: 'Panorama Erdoglija',
    startLat: 44.0109,
    startLong: 20.8938,
    endLat: 44.0109,
    endLong: 20.8938,
  },
  {
    name: 'Panorama Erdoglija',
    startLat: 44.0108,
    startLong: 20.8937,
    endLat: 44.0108,
    endLong: 20.8937,
  },
  {
    name: 'Panorama Erdoglija',
    startLat: 44.011,
    startLong: 20.8937,
    endLat: 44.011,
    endLong: 20.8937,
  },
  {
    name: 'Panorama Erdoglija',
    startLat: 44.011,
    startLong: 20.8935,
    endLat: 44.011,
    endLong: 20.8935,
  },
  { name: 'Zelena Oaza', startLat: 44.0248, startLong: 20.9236, endLat: 44.0248, endLong: 20.9236 },
  { name: 'Zelena Oaza', startLat: 44.0249, startLong: 20.9233, endLat: 44.0249, endLong: 20.9233 },
  { name: 'Garden Hill', startLat: 45.2255, startLong: 19.8624, endLat: 45.2255, endLong: 19.8624 },
  { name: 'Garden Hill', startLat: 45.2254, startLong: 19.8631, endLat: 45.2254, endLong: 19.8631 },
  { name: 'Garden Hill', startLat: 45.2256, startLong: 19.8623, endLat: 45.2256, endLong: 19.8623 },
  { name: 'Garden Hill', startLat: 45.2253, startLong: 19.8628, endLat: 45.2253, endLong: 19.8628 },
  { name: 'Garden Hill', startLat: 45.2253, startLong: 19.863, endLat: 45.2253, endLong: 19.863 },
  {
    name: 'Savska terasa',
    startLat: 44.7296,
    startLong: 20.3686,
    endLat: 44.7296,
    endLong: 20.3686,
  },
  {
    name: 'Savska terasa',
    startLat: 44.7298,
    startLong: 20.3687,
    endLat: 44.7298,
    endLong: 20.3687,
  },
  {
    name: 'Savska terasa',
    startLat: 44.7302,
    startLong: 20.3694,
    endLat: 44.7302,
    endLong: 20.3694,
  },
  {
    name: 'Savska terasa',
    startLat: 44.7302,
    startLong: 20.3691,
    endLat: 44.7302,
    endLong: 20.3691,
  },
  { name: 'Novi Bubanj', startLat: 44.0214, startLong: 20.919, endLat: 44.0214, endLong: 20.919 },
  { name: 'Novi Bubanj', startLat: 44.0213, startLong: 20.9193, endLat: 44.0213, endLong: 20.9193 },
  { name: 'Novi Bubanj', startLat: 44.0212, startLong: 20.9197, endLat: 44.0212, endLong: 20.9197 },
  { name: 'Novi Bubanj', startLat: 44.021, startLong: 20.9197, endLat: 44.021, endLong: 20.9197 },
  {
    name: 'Park Novi Residence',
    startLat: 45.2581,
    startLong: 19.8064,
    endLat: 45.2581,
    endLong: 19.8064,
  },
  {
    name: 'Park Novi Residence',
    startLat: 45.2588,
    startLong: 19.8068,
    endLat: 45.2588,
    endLong: 19.8068,
  },
  {
    name: 'Park Novi Residence',
    startLat: 45.2585,
    startLong: 19.807,
    endLat: 45.2585,
    endLong: 19.807,
  },
  {
    name: 'Park Novi Residence',
    startLat: 45.2583,
    startLong: 19.8071,
    endLat: 45.2583,
    endLong: 19.8071,
  },
  {
    name: 'Park Novi Residence',
    startLat: 45.2586,
    startLong: 19.8061,
    endLat: 45.2586,
    endLong: 19.8061,
  },
  {
    name: 'Park Novi Residence',
    startLat: 45.2582,
    startLong: 19.8063,
    endLat: 45.2582,
    endLong: 19.8063,
  },
  {
    name: 'Park Novi Residence',
    startLat: 45.2584,
    startLong: 19.8066,
    endLat: 45.2584,
    endLong: 19.8066,
  },
  {
    name: 'Kapija Vračara',
    startLat: 44.7934,
    startLong: 20.4818,
    endLat: 44.7934,
    endLong: 20.4818,
  },
  {
    name: 'Kapija Vračara',
    startLat: 44.7933,
    startLong: 20.4816,
    endLat: 44.7933,
    endLong: 20.4816,
  },
  {
    name: 'Kapija Vračara',
    startLat: 44.7933,
    startLong: 20.4817,
    endLat: 44.7933,
    endLong: 20.4817,
  },
  {
    name: 'Kapija Vračara',
    startLat: 44.7933,
    startLong: 20.4818,
    endLat: 44.7933,
    endLong: 20.4818,
  },
  {
    name: 'Central Garden',
    startLat: 44.8108,
    startLong: 20.4767,
    endLat: 44.8108,
    endLong: 20.4767,
  },
  {
    name: 'Central Garden',
    startLat: 44.8111,
    startLong: 20.4766,
    endLat: 44.8111,
    endLong: 20.4766,
  },
  {
    name: 'Central Garden',
    startLat: 44.8106,
    startLong: 20.4776,
    endLat: 44.8106,
    endLong: 20.4776,
  },
  {
    name: 'Central Garden',
    startLat: 44.8117,
    startLong: 20.4772,
    endLat: 44.8117,
    endLong: 20.4772,
  },
  {
    name: 'Central Garden',
    startLat: 44.8119,
    startLong: 20.4766,
    endLat: 44.8119,
    endLong: 20.4766,
  },
  {
    name: 'Central Garden',
    startLat: 44.8107,
    startLong: 20.4768,
    endLat: 44.8107,
    endLong: 20.4768,
  },
  {
    name: 'Central Garden',
    startLat: 44.8112,
    startLong: 20.477,
    endLat: 44.8112,
    endLong: 20.477,
  },
  {
    name: 'Central Garden',
    startLat: 44.8114,
    startLong: 20.4775,
    endLat: 44.8114,
    endLong: 20.4775,
  },
  {
    name: 'Central Garden',
    startLat: 44.8112,
    startLong: 20.4766,
    endLat: 44.8112,
    endLong: 20.4766,
  },
  {
    name: 'Central Garden',
    startLat: 44.8116,
    startLong: 20.4764,
    endLat: 44.8116,
    endLong: 20.4764,
  },
  {
    name: 'Central Garden',
    startLat: 44.8119,
    startLong: 20.477,
    endLat: 44.8119,
    endLong: 20.477,
  },
  {
    name: 'City Residence',
    startLat: 44.7905,
    startLong: 20.4708,
    endLat: 44.7905,
    endLong: 20.4708,
  },
  {
    name: 'City Residence',
    startLat: 44.7905,
    startLong: 20.4707,
    endLat: 44.7905,
    endLong: 20.4707,
  },
  {
    name: 'City Residence',
    startLat: 44.7904,
    startLong: 20.4704,
    endLat: 44.7904,
    endLong: 20.4704,
  },
  {
    name: 'City Residence',
    startLat: 44.7906,
    startLong: 20.471,
    endLat: 44.7906,
    endLong: 20.471,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2632,
    startLong: 19.853,
    endLat: 45.2632,
    endLong: 19.853,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2634,
    startLong: 19.8528,
    endLat: 45.2634,
    endLong: 19.8528,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2634,
    startLong: 19.8522,
    endLat: 45.2634,
    endLong: 19.8522,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2632,
    startLong: 19.8527,
    endLat: 45.2632,
    endLong: 19.8527,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.263,
    startLong: 19.8526,
    endLat: 45.263,
    endLong: 19.8526,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.263,
    startLong: 19.8525,
    endLat: 45.263,
    endLong: 19.8525,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2632,
    startLong: 19.852,
    endLat: 45.2632,
    endLong: 19.852,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.263,
    startLong: 19.8527,
    endLat: 45.263,
    endLong: 19.8527,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2634,
    startLong: 19.8529,
    endLat: 45.2634,
    endLong: 19.8529,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2634,
    startLong: 19.8525,
    endLat: 45.2634,
    endLong: 19.8525,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2634,
    startLong: 19.8526,
    endLat: 45.2634,
    endLong: 19.8526,
  },
  {
    name: 'Kej Garden Residence',
    startLat: 45.2631,
    startLong: 19.8531,
    endLat: 45.2631,
    endLong: 19.8531,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2402,
    startLong: 19.8249,
    endLat: 45.2402,
    endLong: 19.8249,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2401,
    startLong: 19.8238,
    endLat: 45.2401,
    endLong: 19.8238,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2403,
    startLong: 19.8236,
    endLat: 45.2403,
    endLong: 19.8236,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2403,
    startLong: 19.8231,
    endLat: 45.2403,
    endLong: 19.8231,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2401,
    startLong: 19.8234,
    endLat: 45.2401,
    endLong: 19.8234,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2403,
    startLong: 19.8233,
    endLat: 45.2403,
    endLong: 19.8233,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2399,
    startLong: 19.8234,
    endLat: 45.2399,
    endLong: 19.8234,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.24,
    startLong: 19.8243,
    endLat: 45.24,
    endLong: 19.8243,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.24,
    startLong: 19.8246,
    endLat: 45.24,
    endLong: 19.8246,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2401,
    startLong: 19.8245,
    endLat: 45.2401,
    endLong: 19.8245,
  },
  {
    name: 'Avenija Garden Residence',
    startLat: 45.2403,
    startLong: 19.8244,
    endLat: 45.2403,
    endLong: 19.8244,
  },
  { name: 'Pasent home', startLat: 45.27, startLong: 19.8323, endLat: 45.27, endLong: 19.8323 },
  { name: 'Pasent home', startLat: 45.2697, startLong: 19.8324, endLat: 45.2697, endLong: 19.8324 },
  { name: 'Pasent home', startLat: 45.2702, startLong: 19.8322, endLat: 45.2702, endLong: 19.8322 },
  { name: 'Pasent home', startLat: 45.2702, startLong: 19.8323, endLat: 45.2702, endLong: 19.8323 },
  { name: 'Vojvođanka', startLat: 45.2495, startLong: 19.8202, endLat: 45.2495, endLong: 19.8202 },
  { name: 'Vojvođanka', startLat: 45.2495, startLong: 19.8203, endLat: 45.2495, endLong: 19.8203 },
  { name: 'Vojvođanka', startLat: 45.2497, startLong: 19.8202, endLat: 45.2497, endLong: 19.8202 },
  { name: 'Vojvođanka', startLat: 45.2494, startLong: 19.8197, endLat: 45.2494, endLong: 19.8197 },
  { name: 'Vojvođanka', startLat: 45.2497, startLong: 19.8197, endLat: 45.2497, endLong: 19.8197 },
  {
    name: 'Borislava Pekića 5',
    startLat: 44.7991,
    startLong: 20.4714,
    endLat: 44.7991,
    endLong: 20.4714,
  },
  {
    name: 'Pupinova palata',
    startLat: 44.8112,
    startLong: 20.396,
    endLat: 44.8112,
    endLong: 20.396,
  },
  {
    name: 'Pupinova palata',
    startLat: 44.8108,
    startLong: 20.3955,
    endLat: 44.8108,
    endLong: 20.3955,
  },
  {
    name: 'Pupinova palata',
    startLat: 44.8112,
    startLong: 20.3959,
    endLat: 44.8112,
    endLong: 20.3959,
  },
  { name: 'Pupinova palata', startLat: 44.8107, startLong: 20.3949, endLat: 44.8107, endLong: 20.3949 },
  {
    name: 'Doktora Dragoslava Popovića 3',
    startLat: 44.812,
    startLong: 20.4742,
    endLat: 44.812,
    endLong: 20.4742,
  },
  { name: 'Cetinjska 13', startLat: 44.8171, startLong: 20.466, endLat: 44.8171, endLong: 20.466 },
  {
    name: 'Knjeginje Julije 5',
    startLat: 44.7923,
    startLong: 20.4776,
    endLat: 44.7923,
    endLong: 20.4776,
  },
  {
    name: 'Slobodana Principa-Selje 11',
    startLat: 44.7763,
    startLong: 20.4178,
    endLat: 44.7763,
    endLong: 20.4178,
  },
  {
    name: 'Nevesinjska 1',
    startLat: 44.7992,
    startLong: 20.4726,
    endLat: 44.7992,
    endLong: 20.4726,
  },
  {
    name: 'Metohijska 18',
    startLat: 44.7935,
    startLong: 20.4919,
    endLat: 44.7935,
    endLong: 20.4919,
  },
  {
    name: 'Mira Popare 32',
    startLat: 44.7696,
    startLong: 20.4165,
    endLat: 44.7696,
    endLong: 20.4165,
  },
  {
    name: 'Sime Igumanova 35',
    startLat: 44.792,
    startLong: 20.4739,
    endLat: 44.792,
    endLong: 20.4739,
  },
  {
    name: 'Bore Prodanovića 13',
    startLat: 44.8066,
    startLong: 20.4951,
    endLat: 44.8066,
    endLong: 20.4951,
  },
  { name: 'Silva Vidikovac', startLat: 44.7439, startLong: 20.4295, endLat: 44.7439, endLong: 20.4295 },
  { name: 'Silva Vidikovac', startLat: 44.7442, startLong: 20.4296, endLat: 44.7442, endLong: 20.4296 },
  { name: 'Silva Vidikovac', startLat: 44.7441, startLong: 20.4291, endLat: 44.7441, endLong: 20.4291 },
  { name: 'Silva Vidikovac', startLat: 44.7445, startLong: 20.4292, endLat: 44.7445, endLong: 20.4292 },
  {
    name: 'Južni bulevar 39-41',
    startLat: 44.7933,
    startLong: 20.4762,
    endLat: 44.7933,
    endLong: 20.4762,
  },
  {
    name: 'Živka Davidovića 23',
    startLat: 44.7912,
    startLong: 20.5111,
    endLat: 44.7912,
    endLong: 20.5111,
  },
  {
    name: 'Dobanovačka 93',
    startLat: 44.8498,
    startLong: 20.3948,
    endLat: 44.8498,
    endLong: 20.3948,
  },
  {
    name: 'Ljubice Luković 10',
    startLat: 44.8088,
    startLong: 20.4973,
    endLat: 44.8088,
    endLong: 20.4973,
  },
  {
    name: 'Cara Dušana 186',
    startLat: 44.8549,
    startLong: 20.3882,
    endLat: 44.8549,
    endLong: 20.3882,
  },
  { name: 'Cara Dušana 186', startLat: 44.8552, startLong: 20.3884, endLat: 44.8552, endLong: 20.3884 },
  { name: 'Banatska 6', startLat: 44.8505, startLong: 20.3798, endLat: 44.8505, endLong: 20.3798 },
  {
    name: 'Milorada Miškovića 36',
    startLat: 44.7522,
    startLong: 20.4756,
    endLat: 44.7522,
    endLong: 20.4756,
  },
  {
    name: 'Koste Jovanovića 2',
    startLat: 44.7832,
    startLong: 20.4681,
    endLat: 44.7832,
    endLong: 20.4681,
  },
  { name: 'Cerska 26', startLat: 44.7959, startLong: 20.4801, endLat: 44.7959, endLong: 20.4801 },
  { name: 'Budimska 15', startLat: 44.8182, startLong: 20.4725, endLat: 44.8182, endLong: 20.4725 },
  { name: 'Ariljačka 8', startLat: 44.7851, startLong: 20.5306, endLat: 44.7851, endLong: 20.5306 },
  {
    name: 'Kapetana Miloša Žunjića 41',
    startLat: 44.7957,
    startLong: 20.5421,
    endLat: 44.7957,
    endLong: 20.5421,
  },
  {
    name: 'Kapetana Miloša Žunjića 41',
    startLat: 44.7953,
    startLong: 20.5423,
    endLat: 44.7953,
    endLong: 20.5423,
  },
  {
    name: 'Aleksandra Bugarskog 20',
    startLat: 44.7964,
    startLong: 20.5376,
    endLat: 44.7964,
    endLong: 20.5376,
  },
  {
    name: 'Puškinova 1a',
    startLat: 44.7852,
    startLong: 20.4395,
    endLat: 44.7852,
    endLong: 20.4395,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8427,
    startLong: 20.4019,
    endLat: 44.8427,
    endLong: 20.4019,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8425,
    startLong: 20.4023,
    endLat: 44.8425,
    endLong: 20.4023,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8428,
    startLong: 20.4014,
    endLat: 44.8428,
    endLong: 20.4014,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8424,
    startLong: 20.4027,
    endLat: 44.8424,
    endLong: 20.4027,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8429,
    startLong: 20.4007,
    endLat: 44.8429,
    endLong: 20.4007,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8433,
    startLong: 20.3996,
    endLat: 44.8433,
    endLong: 20.3996,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8431,
    startLong: 20.4001,
    endLat: 44.8431,
    endLong: 20.4001,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.843,
    startLong: 20.4016,
    endLat: 44.843,
    endLong: 20.4016,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8431,
    startLong: 20.4002,
    endLat: 44.8431,
    endLong: 20.4002,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8431,
    startLong: 20.4002,
    endLat: 44.8431,
    endLong: 20.4002,
  },
  {
    name: 'Novogradska 65-67',
    startLat: 44.8426,
    startLong: 20.4018,
    endLat: 44.8426,
    endLong: 20.4018,
  },

  {
    name: 'Prizrenska 5-7',
    startLat: 44.8511,
    startLong: 20.3841,
    endLat: 44.8511,
    endLong: 20.3841,
  },
  { name: 'Tetovska 32', startLat: 44.7896, startLong: 20.4827, endLat: 44.7896, endLong: 20.4827 },
  {
    name: 'Vojvode Hrvoja 22',
    startLat: 44.7916,
    startLong: 20.4739,
    endLat: 44.7916,
    endLong: 20.4739,
  },
  {
    name: 'Pregrevica 60',
    startLat: 44.8529,
    startLong: 20.3985,
    endLat: 44.8529,
    endLong: 20.3985,
  },
  {
    name: 'Otokara Keršovanija 2a',
    startLat: 44.7638,
    startLong: 20.4797,
    endLat: 44.7638,
    endLong: 20.4797,
  },
  {
    name: 'Gospodar Jovanova 54',
    startLat: 44.8193,
    startLong: 20.4633,
    endLat: 44.8193,
    endLong: 20.4633,
  },
  {
    name: 'Mirijevski san',
    startLat: 44.7917,
    startLong: 20.5452,
    endLat: 44.7917,
    endLong: 20.5452,
  },
  {
    name: 'Mirijevski san',
    startLat: 44.7918,
    startLong: 20.5454,
    endLat: 44.7918,
    endLong: 20.5454,
  },
  { name: 'Dolačka 3', startLat: 44.7852, startLong: 20.5299, endLat: 44.7852, endLong: 20.5299 },
  {
    name: 'Šumatovačka 155',
    startLat: 44.7933,
    startLong: 20.4863,
    endLat: 44.7933,
    endLong: 20.4863,
  },
  {
    name: 'Ruže Jovanović 17',
    startLat: 44.7875,
    startLong: 20.5189,
    endLat: 44.7875,
    endLong: 20.5189,
  },
  {
    name: 'Cara Dušana 220',
    startLat: 44.8569,
    startLong: 20.3845,
    endLat: 44.8569,
    endLong: 20.3845,
  },
  {
    name: 'Preradovićeva 7',
    startLat: 44.811,
    startLong: 20.4843,
    endLat: 44.811,
    endLong: 20.4843,
  },
  {
    name: 'Karpoševa 40',
    startLat: 44.7842,
    startLong: 20.4149,
    endLat: 44.7842,
    endLong: 20.4149,
  },
  {
    name: 'Veselina Masleše 76',
    startLat: 44.7907,
    startLong: 20.4912,
    endLat: 44.7907,
    endLong: 20.4912,
  },
  { name: 'Sazonova 26', startLat: 44.7966, startLong: 20.4795, endLat: 44.7966, endLong: 20.4795 },
  { name: 'Tetovska 64', startLat: 44.7894, startLong: 20.486, endLat: 44.7894, endLong: 20.486 },
  {
    name: 'Garden 2 Zemun',
    startLat: 44.8428,
    startLong: 20.3774,
    endLat: 44.8428,
    endLong: 20.3774,
  },
  {
    name: 'Admirala Vukovića 49',
    startLat: 44.7848,
    startLong: 20.4735,
    endLat: 44.7848,
    endLong: 20.4735,
  },
  { name: 'Bakićeva 14', startLat: 44.7894, startLong: 20.4914, endLat: 44.7894, endLong: 20.4914 },
  { name: 'Sigmada', startLat: 44.8363, startLong: 20.4053, endLat: 44.8363, endLong: 20.4053 },
  { name: 'Šilerova 52', startLat: 44.8462, startLong: 20.388, endLat: 44.8462, endLong: 20.388 },
  { name: 'Dunavske terase', startLat: 44.8166, startLong: 20.5053, endLat: 44.8166, endLong: 20.5053 },
  { name: 'Dunavske terase', startLat: 44.8166, startLong: 20.505, endLat: 44.8166, endLong: 20.505 },
  { name: 'Dunavske terase', startLat: 44.8161, startLong: 20.505, endLat: 44.8161, endLong: 20.505 },
  { name: 'Dunavske terase', startLat: 44.8161, startLong: 20.5053, endLat: 44.8161, endLong: 20.5053 },
  { name: 'Dunavske terase', startLat: 44.8162, startLong: 20.5049, endLat: 44.8162, endLong: 20.5049 },
  { name: 'Banovo brdo Residences', startLat: 44.766, startLong: 20.4144, endLat: 44.766, endLong: 20.4144 },
  { name: 'Banovo brdo Residences', startLat: 44.7657, startLong: 20.4155, endLat: 44.7657, endLong: 20.4155 },
  { name: 'Banovo brdo Residences', startLat: 44.7658, startLong: 20.415, endLat: 44.7658, endLong: 20.415 },
  { name: 'Energa+', startLat: 44.7734, startLong: 20.4136, endLat: 44.7734, endLong: 20.4136 },
  { name: 'Krfska 1', startLat: 44.8025, startLong: 20.4908, endLat: 44.8025, endLong: 20.4908 },
  { name: 'Vilovskog 6', startLat: 44.7968, startLong: 20.4822, endLat: 44.7968, endLong: 20.4822 },
  { name: 'Tetovska 14', startLat: 44.7896, startLong: 20.4807, endLat: 44.7896, endLong: 20.4807 },
  { name: 'Đorđa Radojlovića 3', startLat: 44.7742, startLong: 20.4605, endLat: 44.7742, endLong: 20.4605 },
  { name: 'Radoja Dakića 51-53', startLat: 44.8361, startLong: 20.4056, endLat: 44.8361, endLong: 20.4056 },
  { name: 'Karpoševa 24', startLat: 44.7849, startLong: 20.4158, endLat: 44.7849, endLong: 20.4158 },
  { name: 'Stevana Lilića 3', startLat: 44.784, startLong: 20.4717, endLat: 44.784, endLong: 20.4717 },
  { name: 'Republic Residence', startLat: 44.7642, startLong: 20.4155, endLat: 44.7642, endLong: 20.4155 },
  { name: 'A2Stil', startLat: 44.8168, startLong: 20.4982, endLat: 44.8168, endLong: 20.4982 },
  { name: 'Rezidencijalni kompleks Branko Pešić', startLat: 44.8518, startLong: 20.3973, endLat: 44.8518, endLong: 20.3973 },
  { name: 'Rezidencijalni kompleks Branko Pešić', startLat: 44.8515, startLong: 20.397, endLat: 44.8515, endLong: 20.397 },
  { name: 'Airport Garden', startLat: 44.8107, startLong: 20.3948, endLat: 44.8107, endLong: 20.3948 },
  { name: 'Airport Garden', startLat: 44.8106, startLong: 20.3948, endLat: 44.8106, endLong: 20.3948 },
  { name: 'Airport Garden', startLat: 44.8113, startLong: 20.3937, endLat: 44.8113, endLong: 20.3937 },
  { name: 'Jasenička 17', startLat: 44.7877, startLong: 20.4974, endLat: 44.7877, endLong: 20.4974 },
  { name: 'Petrovaradinska 29', startLat: 44.7896, startLong: 20.4884, endLat: 44.7896, endLong: 20.4884 },
  { name: 'Kolašinska 43', startLat: 44.7901, startLong: 20.4889, endLat: 44.7901, endLong: 20.4889 },
  { name: 'Vojvode Bojovića 7', startLat: 44.7911, startLong: 20.5048, endLat: 44.7911, endLong: 20.5048 },
  { name: 'Koste Jovanovića 10', startLat: 44.7827, startLong: 20.4684, endLat: 44.7827, endLong: 20.4684 },
  { name: 'Milana Rakića 88', startLat: 44.7944, startLong: 20.5123, endLat: 44.7944, endLong: 20.5123 },
  { name: 'Danijelova 21', startLat: 44.7833, startLong: 20.4783, endLat: 44.7833, endLong: 20.4783 },
  { name: 'Danijelova 21', startLat: 44.7833, startLong: 20.4784, endLat: 44.7833, endLong: 20.4784 },
  { name: 'Josipa Slavenskog 13-15', startLat: 44.7548, startLong: 20.4736, endLat: 44.7548, endLong: 20.4736 },
  { name: 'Josipa Slavenskog 13-15', startLat: 44.755, startLong: 20.4739, endLat: 44.755, endLong: 20.4739 },
  { name: 'Josipa Slavenskog 13-15', startLat: 44.7551, startLong: 20.4741, endLat: 44.7551, endLong: 20.4741 },
  { name: 'Sarajevska 3', startLat: 44.8505, startLong: 20.3808, endLat: 44.8505, endLong: 20.3808 },
  { name: 'Kalman Dedinje', startLat: 44.7659, startLong: 20.4586, endLat: 44.7659, endLong: 20.4586 },
  { name: 'Ustanička 84a', startLat: 44.7847, startLong: 20.4838, endLat: 44.7847, endLong: 20.4838 },
  { name: 'Mariborska 5', startLat: 44.8033, startLong: 20.4903, endLat: 44.8033, endLong: 20.4903 },
  { name: 'Pregrevica 39', startLat: 44.8523, startLong: 20.3986, endLat: 44.8523, endLong: 20.3986 },
  { name: 'Učiteljska 3', startLat: 44.7882, startLong: 20.5138, endLat: 44.7882, endLong: 20.5138 },
  { name: 'Kosmaj Renew', startLat: 44.4569, startLong: 20.556, endLat: 44.4569, endLong: 20.556 },
  { name: 'Kosmaj Renew', startLat: 44.4566, startLong: 20.5569, endLat: 44.4566, endLong: 20.5569 },
  { name: 'Kosmaj Renew', startLat: 44.4565, startLong: 20.5574, endLat: 44.4565, endLong: 20.5574 },
  { name: 'Kosmaj Renew', startLat: 44.4568, startLong: 20.5564, endLat: 44.4568, endLong: 20.5564 },
  { name: 'Kosmaj Renew', startLat: 44.4568, startLong: 20.5563, endLat: 44.4568, endLong: 20.5563 },
  { name: 'Kosmaj Renew', startLat: 44.4566, startLong: 20.5574, endLat: 44.4566, endLong: 20.5574 },
  { name: 'Kovačeva 36', startLat: 44.7886, startLong: 20.5051, endLat: 44.7886, endLong: 20.5051 },
];
