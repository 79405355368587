export const typeId = 831;

export const months = [
  { value: 1, stringValue: '01', label: 'Januar', days: 31, short: 'JAN' },
  { value: 2, stringValue: '02', label: 'Februar', days: 28, short: 'FEB' },
  { value: 3, stringValue: '03', label: 'Mart', days: 31, short: 'MAR' },
  { value: 4, stringValue: '04', label: 'April', days: 30, short: 'APR' },
  { value: 5, stringValue: '05', label: 'Maj', days: 31, short: 'MAJ' },
  { value: 6, stringValue: '06', label: 'Jun', days: 30, short: 'JUN' },
  { value: 7, stringValue: '07', label: 'Jul', days: 31, short: 'JUL' },
  { value: 8, stringValue: '08', label: 'Avgust', days: 31, short: 'AVG' },
  { value: 9, stringValue: '09', label: 'Septembar', days: 30, short: 'SEP' },
  { value: 10, stringValue: '10', label: 'Oktobar', days: 31, short: 'OKT' },
  { value: 11, stringValue: '11', label: 'Novembar', days: 30, short: 'NOV' },
  { value: 12, stringValue: '12', label: 'Decembar', days: 31, short: 'DEC' },
];

export const quarters = [
  {
    value: 1,
    stringValue: '01',
    label: 'Q1',
    short: 'Q1',
    firstMonth: 1,
    lastMonthString: '03',
    monthStrings: ['01', '02', '03'],
  },
  {
    value: 2,
    stringValue: '04',
    label: 'Q2',
    short: 'Q2',
    firstMonth: 4,
    lastMonthString: '06',
    monthStrings: ['04', '05', '06'],
  },
  {
    value: 3,
    stringValue: '07',
    label: 'Q3',
    short: 'Q3',
    firstMonth: 7,
    lastMonthString: '09',
    monthStrings: ['07', '08', '09'],
  },
  {
    value: 4,
    stringValue: '10',
    label: 'Q4',
    short: 'Q4',
    firstMonth: 10,
    lastMonthString: '12',
    monthStrings: ['10', '11', '12'],
  },
];

export const years = [
  { value: 2014, label: '2014', short: '14' },
  { value: 2015, label: '2015', short: '15' },
  { value: 2016, label: '2016', short: '16' },
  { value: 2017, label: '2017', short: '17' },
  { value: 2018, label: '2018', short: '18' },
  { value: 2019, label: '2019', short: '19' },
  { value: 2020, label: '2020', short: '20' },
  { value: 2021, label: '2021', short: '21' },
  { value: 2022, label: '2022', short: '22' },
  { value: 2023, label: '2023', short: '23' },
  { value: 2024, label: '2024', short: '24' },
];

export const defaultMonth = months[8];
export const lastMonth = months[9];
export const defaultQuarter = quarters[2];
export const lastQuarter = quarters[3];
export const defaultYear = years[10];
export const lastYear = years[10];
export const defaultQuarterlyYear = years[10];
export const lastQuarterlyYear = years[10];
export const defaultRegion = { value: '1', label: 'Beograd' };
export const defaultOption = { value: '-1', label: 'Sve' };

export const cities = [
  {
    value: 1,
    regions: [70106, 70114, 70149, 70157, 70181, 70203, 70211, 70220, 70246, 70254],
    label: 'Beograd',
  },
  {
    value: 3,
    regions: [71331, 71323, 71307, 71315],
    label: 'Niš',
  },
];

export const regionsWithCities = [
  { value: '1', label: 'Beograd' },
  { value: '70254', label: 'Čukarica' },
  { value: '70181', label: 'Novi Beograd' },
  { value: '70203', label: 'Palilula' },
  { value: '70211', label: 'Rakovica' },
  { value: '70220', label: 'Savski venac' },
  { value: '70246', label: 'Stari grad' },
  { value: '70106', label: 'Voždovac' },
  { value: '70114', label: 'Vračar' },
  { value: '70157', label: 'Zemun' },
  { value: '70149', label: 'Zvezdara' },
  { value: '89010', label: 'Novi Sad' },
  { value: '3', label: 'Niš' },
  { value: '70645', label: 'Kragujevac' },
  { value: '70033', label: 'Aranđelovac' },
  { value: '80063', label: 'Bačka Palanka' },
  { value: '70092', label: 'Barajevo' },
  { value: '70327', label: 'Bor' },
  { value: '71242', label: 'Čačak' },
  { value: '71200', label: 'Ćuprija' },
  { value: '70483', label: 'Gornji Milanovac' },
  { value: '70122', label: 'Grocka' },
  { value: '80179', label: 'Inđija' },
  { value: '71048', label: 'Jagodina' },
  { value: '80209', label: 'Kikinda' },
  { value: '71021', label: 'Kopaonik' },
  { value: '80225', label: 'Kovin' },
  { value: '70653', label: 'Kraljevo' },
  { value: '70670', label: 'Kruševac' },
  { value: '70165', label: 'Lazarevac' },
  { value: '70726', label: 'Leskovac' },
  { value: '70734', label: 'Loznica' },
  { value: '70785', label: 'Majdanpek' },
  { value: '70173', label: 'Mladenovac' },
  { value: '70840', label: 'Negotin' },
  { value: '70874', label: 'Novi Pazar' },
  { value: '70190', label: 'Obrenovac' },
  { value: '80314', label: 'Pančevo' },
  { value: '70904', label: 'Paraćin' },
  { value: '70939', label: 'Pirot' },
  { value: '70947', label: 'Požarevac' },
  { value: '70998', label: 'Prokuplje' },
  { value: '80357', label: 'Ruma' },
  { value: '71099', label: 'Smederevo' },
  { value: '71102', label: 'Smederevska Palanka' },
  { value: '71129', label: 'Sokobanja' },
  { value: '80381', label: 'Sombor' },
  { value: '70238', label: 'Sopot' },
  { value: '80403', label: 'Sremska Mitrovica' },
  { value: '80420', label: 'Stara Pazova' },
  { value: '80438', label: 'Subotica' },
  { value: '71293', label: 'Surčin' },
  { value: '71269', label: 'Šabac' },
  { value: '80446', label: 'Temerin' },
  { value: '71188', label: 'Tutin' },
  { value: '71218', label: 'Ub' },
  { value: '71145', label: 'Užice' },
  { value: '70360', label: 'Valjevo' },
  { value: '70386', label: 'Velika Plana' },
  { value: '70394', label: 'Veliko Gradište' },
  { value: '70432', label: 'Vranje' },
  { value: '80462', label: 'Vrbas' },
  { value: '80128', label: 'Vršac' },
  { value: '70459', label: 'Vrnjačka Banja' },
  { value: '70556', label: 'Zaječar' },
  { value: '71234', label: 'Zlatibor' },
  { value: '80152', label: 'Zrenjanin' },
];

export const subRegionsWithCities = {
  1: [{ value: '-1', label: 'Sve' }],
  3: [
    { value: '-1', label: 'Sve' },
    { value: '71331', label: 'Medijana' },
    { value: '71307', label: 'Pantelej' },
    { value: '71315', label: 'Crveni krst' },
    { value: '71323', label: 'Palilula' },
  ],
  71331: [{ value: '-1', label: 'Sve' }],
  71323: [{ value: '-1', label: 'Sve' }],
  71307: [{ value: '-1', label: 'Sve' }],
  71315: [{ value: '-1', label: 'Sve' }],
  70106: [
    { value: '-1', label: 'Sve' },
    { value: '703621', label: 'Voždovac' },
    { value: '703800', label: 'Kumodraž' },
    { value: '703761', label: 'Jajinci' },
    { value: '703931', label: 'Rakovica selo' },
    { value: '703494', label: 'Beli potok' },
    { value: '703915', label: 'Pinosava' },
    { value: '703753', label: 'Zuce' },
    { value: '703958', label: 'Ripanj' },
  ],
  70114: [
    { value: '-1', label: 'Sve' },
    { value: '703648', label: 'Vračar' },
  ],
  70149: [
    { value: '-1', label: 'Sve' },
    { value: '703745', label: 'Zvezdara' },
    { value: '703885', label: 'Mirijevo' },
    { value: '703842', label: 'Mali mokri lug' },
    { value: '703583', label: 'Veliki mokri lug' },
  ],
  70157: [
    { value: '-1', label: 'Sve' },
    { value: '716065', label: 'Zemun' },
    { value: '716073', label: 'Zemun polje' },
    { value: '716138', label: 'Ugrinovci' },
    { value: '716014', label: 'Batajnica' },
  ],
  70181: [
    { value: '-1', label: 'Sve' },
    { value: '716090', label: 'Novi Beograd' },
  ],
  70203: [
    { value: '-1', label: 'Sve' },
    { value: '703907', label: 'Palilula' },
    { value: '703613', label: 'Višnjica' },
    { value: '704016', label: 'Slanci' },
    { value: '703591', label: 'Veliko selo' },
    { value: '732214', label: 'Krnjača' },
    { value: '732184', label: 'Borča' },
    { value: '732249', label: 'Ovča' },
    { value: '732192', label: 'Kovilovo' },
    { value: '732176', label: 'Besni Fok' },
    { value: '732222', label: 'Lepušnica' },
    { value: '732206', label: 'Komareva humka' },
  ],
  70211: [
    { value: '-1', label: 'Sve' },
    { value: '704032', label: 'Stara Rakovica' },
    { value: '703796', label: 'Kneževac' },
    { value: '703940', label: 'Resnik' },
  ],
  70220: [
    { value: '-1', label: 'Sve' },
    { value: '704008', label: 'Savski venac' },
  ],
  70246: [
    { value: '-1', label: 'Sve' },
    { value: '704059', label: 'Stari grad' },
  ],
  70254: [
    { value: '-1', label: 'Sve' },
    { value: '704083', label: 'Čukarica' },
    { value: '703702', label: 'Železnik' },
    { value: '704024', label: 'Sremčica' },
    { value: '703982', label: 'Rušanj' },
    { value: '703893', label: 'Ostružnica' },
    { value: '704067', label: 'Umka' },
    { value: '703559', label: 'Velika Moštanica' },
  ],
  89010: [
    { value: '-1', label: 'Sve' },
    { value: '802158', label: 'Novi Sad I' },
    { value: '802166', label: 'Novi Sad II' },
    { value: '802174', label: 'Novi Sad III' },
    { value: '802182', label: 'Novi Sad IV' },
    { value: '805378', label: 'Petrovaradin' },
    { value: '803308', label: 'Sremska Kamenica' },
    { value: '802298', label: 'Veternik' },
    { value: '802255', label: 'Futog' },
  ],
  71234: [
    { value: '-1', label: 'Sve' },
    { value: '743968', label: 'Zlatibor' },
  ],
  70645: [
    { value: '-1', label: 'Sve' },
    { value: '745081', label: 'Kragujevac I' },
    { value: '745090', label: 'Kragujevac II' },
    { value: '745103', label: 'Kragujevac III' },
    { value: '745111', label: 'Kragujevac IV' },
  ],
  80314: [
    { value: '-1', label: 'Sve' },
    { value: '802484', label: 'Pančevo' },
  ],
  71242: [
    { value: '-1', label: 'Sve' },
    { value: '744298', label: 'Čačak' },
  ],
  71021: [
    { value: '-1', label: 'Sve' },
    { value: '745154', label: 'Kopaonik' },
  ],
  70360: [
    { value: '-1', label: 'Sve' },
    { value: '707830', label: 'Valjevo' },
  ],
  70670: [
    { value: '-1', label: 'Sve' },
    { value: '720968', label: 'Kruševac' },
  ],
  80420: [
    { value: '-1', label: 'Sve' },
    { value: '805424', label: 'Stara Pazova' },
    { value: '805319', label: 'Nova Pazova' },
    { value: '805327', label: 'Novi Banovci' },
    { value: '805432', label: 'Stari Banovci' },
  ],
  80438: [
    { value: '-1', label: 'Sve' },
    { value: '804398', label: 'Donji grad' },
    { value: '804479', label: 'Novi grad' },
    { value: '804517', label: 'Stari grad' },
  ],
  70874: [
    { value: '-1', label: 'Sve' },
    { value: '731056', label: 'Novi Pazar' },
  ],
  70459: [
    { value: '-1', label: 'Sve' },
    { value: '741558', label: 'Vrnjačka Banja' },
  ],
  80381: [
    { value: '-1', label: 'Sve' },
    { value: '803952', label: 'Sombor I' },
  ],
  71048: [
    { value: '-1', label: 'Sve' },
    { value: '738409', label: 'Jagodina' },
  ],
  70734: [
    { value: '-1', label: 'Sve' },
    { value: '745189', label: 'Loznica' },
  ],
  70653: [
    { value: '-1', label: 'Sve' },
    { value: '719790', label: 'Kraljevo' },
  ],
  71269: [
    { value: '-1', label: 'Sve' },
    { value: '745014', label: 'Šabac' },
  ],
  71145: [
    { value: '-1', label: 'Sve' },
    { value: '741442', label: 'Užice' },
  ],
  80152: [
    { value: '-1', label: 'Sve' },
    { value: '805742', label: 'Zrenjanin I' },
  ],
  80179: [{ value: '-1', label: 'Sve' }],
  80357: [{ value: '-1', label: 'Sve' }],
  80063: [{ value: '-1', label: 'Sve' }],
  80462: [{ value: '-1', label: 'Sve' }],
  80209: [{ value: '-1', label: 'Sve' }],
  80446: [{ value: '-1', label: 'Sve' }],
  70092: [{ value: '-1', label: 'Sve' }],
  70122: [{ value: '-1', label: 'Sve' }],
  70165: [{ value: '-1', label: 'Sve' }],
  70173: [{ value: '-1', label: 'Sve' }],
  70190: [{ value: '-1', label: 'Sve' }],
  70238: [{ value: '-1', label: 'Sve' }],
  71293: [{ value: '-1', label: 'Sve' }],
  70726: [{ value: '-1', label: 'Sve' }],
  70939: [{ value: '-1', label: 'Sve' }],
  70998: [{ value: '-1', label: 'Sve' }],
  71129: [{ value: '-1', label: 'Sve' }],
  70432: [{ value: '-1', label: 'Sve' }],
  70033: [{ value: '-1', label: 'Sve' }],
  70904: [{ value: '-1', label: 'Sve' }],
  71188: [{ value: '-1', label: 'Sve' }],
  71200: [{ value: '-1', label: 'Sve' }],
  71218: [{ value: '-1', label: 'Sve' }],
  70483: [{ value: '-1', label: 'Sve' }],
  71099: [{ value: '-1', label: 'Sve' }],
  71102: [{ value: '-1', label: 'Sve' }],
  80403: [{ value: '-1', label: 'Sve' }],
  70386: [{ value: '-1', label: 'Sve' }],
  80128: [{ value: '-1', label: 'Sve' }],
  70327: [{ value: '-1', label: 'Sve' }],
  70394: [{ value: '-1', label: 'Sve' }],
  70556: [{ value: '-1', label: 'Sve' }],
  70947: [{ value: '-1', label: 'Sve' }],
  80225: [{ value: '-1', label: 'Sve' }],
  70785: [{ value: '-1', label: 'Sve' }],
  70840: [{ value: '-1', label: 'Sve' }],
};
